import React, { useState, useEffect, useContext , useReducer, Fragment, Text } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Utilities, AcquisitionRepository, AcquisitionProcessingAPI, AcquisitionAPI, TranslationRepository, UserRepository } from '@warnerconnect/library';

import { TablePagination, Avatar, ListItemAvatar, Box, Button, Typography, Switch, CircularProgress, Stack, Divider, Paper, TextField, Tooltip, Link as MLink, InputLabel, Grid, Autocomplete, NativeSelect, Select, MenuItem, InputAdornment, List, ListItem, ListItemText, Checkbox, IconButton, ListItemIcon, ListItemButton, FormControlLabel, RadioGroup, Radio, Chip, Slider, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, AppBar, Toolbar, Fab, Container, Card, CardContent, Alert, CardActions } from '@mui/material';
import { styled } from '@mui/material/styles';

import {LocalizationProvider, TimePicker, DateTimePicker, DesktopDatePicker, MobileDatePicker} from '@mui/x-date-pickers';
import {LoadingButton} from '@mui/lab';

import Flag from 'react-world-flags'

import { useAuthState } from '../../../Context';
import { useSnackbar } from "notistack";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import FormContainerUriBackgroundImage from '../../../assets/img/formContainerUris.png';

import OptInDialogueInline from '../../../assets/img/optin.dialogue.inline.png'
import OptInDialogueInlineActive from '../../../assets/img/optin.dialogue.inline.on.png'

import OptInDialoguePopup from '../../../assets/img/optin.dialogue.popup.png'
import OptInDialoguePopupActive from '../../../assets/img/optin.dialogue.popup.on.png'

import OptInTypeComplex from '../../../assets/img/optin.type.complex.png'
import OptInTypeComplexActive from '../../../assets/img/optin.type.complex.on.png'

import OptInTypeSimple from '../../../assets/img/optin.type.simple.png'
import OptInTypeSimpleActive from '../../../assets/img/optin.type.simple.on.png'

import OptInTypeSignUp from '../../../assets/img/optin.type.signup.png'
import OptInTypeSignUpActive from '../../../assets/img/optin.type.signup.on.png'

import CampaignList from '../../Elements/CampaignList';
import { flattenObject, mergeObjects, isObject } from '../../Utilities/Utilities';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import exactTargetGenres from '../../Utilities/exactTargetGenres';

import { Redirect, useParams } from 'react-router';
import PageContainer from '../../Elements/PageContainer';
import Hero from '../../Elements/Hero';
import RadioGroupLinear from '../../Elements/Forms/Fields/RadioGroupLinear';
import OptIn from '../../Elements/Forms/Fields/OptIn';
import ModalDialogue from '../../Elements/ModalDialogue';
import UriList from '../../Elements/Forms/Fields/UriList';
import CountrySelector from '../../Elements/Forms/Fields/CountrySelector';
import ImageUpload from '../../Elements/Forms/Fields/ImageUpload';
import FileUpload from '../../Elements/Forms/Fields/FileUpload';
import Services from '../../Elements/Forms/Fields/Services';
import FormBuilderFields from '../../Elements/Forms/Fields/FormBuilderFields';
import RichTextEditor from '../../Elements/Forms/Fields/RichTextEditor';
import ImageRadio from '../../Elements/Forms/Fields/ImageRadio';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import Fieldset from '../../Elements/Forms/Fieldset';
import FieldContainer from '../../Elements/Forms/FieldContainer';
import HeroButton from '../../Elements/HeroButton';
import TabGroup from '../../Elements/TabGroup';
import Accordion from '../../Elements/Accordion';
import ContentBox from '../../Elements/ContentBox';
import TableBarChart from '../../Elements/TableBarChart';
import EntrantList from '../../Elements/EntrantList';
import EntrantListSingle from '../../Elements/EntrantListSingle';
import DownloadCodeForm from '../../Elements/DownloadCodeForm';

import {
    Info as InfoIcon,
    ShowChart as ShowChartIcon,
    Visibility as VisibilityIcon,
    MoreHoriz as MoreHorizIcon,
    Save as SaveIcon,
    AddBox as AddBoxIcon,
    Cancel as CancelIcon,
    Edit as EditIcon,
    OpenInNew as OpenInNewIcon,
    CloudUpload as CloudUploadIcon,

    PersonAddAlt as CampaignFollowIcon,
    LibraryMusic as CampaignSaveIcon,
    NewReleases as CampaignPreSaveIcon,
    LockOpen as CampaignContentUnlockIcon,
    MusicNote as CampaignStreamToEnterIcon,
    Headphones as CampaignListeningHistoryIcon,
    DynamicForm as CampaignFormIcon,
    Downloading as CampaignRedeemableDownloadCodeIcon,
    SubdirectoryArrowRight as SubdirectoryArrowRightIcon
} from '@mui/icons-material';

import HeroHeader from '../../Elements/HeroHeader';
import { productFamilies, supportedIntegrations } from '../../Utilities/Services';
import CampaignTypeSelector from '../../Elements/CampaignTypeSelector';
import LayoutPreview from '../../Elements/LayoutPreview';
import { defaultCampaignConfigurationFields, defaultCampaignTranslationFields, campaignTypes, campaignFooterLinks } from '../../../Config/campaigns';
import ContextMenu from '../../Elements/ContextMenu';
import CampaignHeaderButtons from '../../Elements/CampaignHeaderButtons';
import LargeFileUpload from '../../Elements/Forms/Fields/LargeFileUpload';
import Image from 'mui-image';

var defaultFormValues = {};

campaignFooterLinks.forEach(footerLink => {
    defaultCampaignConfigurationFields['footer.buttons.'+footerLink.id+'.enabled'] = undefined;
    defaultCampaignConfigurationFields['footer.buttons.'+footerLink.id+'.url'] = undefined;
});

supportedIntegrations.forEach(streamingService => {
    defaultCampaignTranslationFields['integrations.'+streamingService.id+'.text.default'] = undefined;
    defaultCampaignTranslationFields['integrations.'+streamingService.id+'.text.preSave'] = undefined;
    defaultCampaignTranslationFields['integrations.'+streamingService.id+'.text.save'] = undefined;
    defaultCampaignConfigurationFields['integrations.'+streamingService.id+'.backgroundColor'] = undefined;
    defaultCampaignConfigurationFields['integrations.'+streamingService.id+'.textColor'] = undefined;
});

Object.keys(defaultCampaignConfigurationFields).forEach( defaultCampaignConfigurationFieldsKey => {
    defaultFormValues[defaultCampaignConfigurationFieldsKey] = undefined;
});

Object.keys(defaultCampaignTranslationFields).forEach( defaultCampaignTranslationFieldsKey => {
    defaultFormValues[defaultCampaignTranslationFieldsKey] = undefined;
});

export default function CampaignsEdit(props) {
    let query = useQuery();
    const history = useHistory();

    useTitle('Edit Campaign');

    const { campaignId, campaignLocaleId, campaignTranslationId } = useParams();

    const userDetails = useAuthState();
    const { enqueueSnackbar } = useSnackbar();

    const [translationCreateFormData, setTranslationCreateFormData] = useReducer(
        ( currentValues, newValues ) => ({...currentValues, ...newValues}), {}
    );

    const [localeCreateFormData, setLocaleCreateFormData] = useReducer(
        ( currentValues, newValues ) => ({...currentValues, ...newValues}), {}
    );

    const [formData, setFormData] = useReducer(
        ( currentValues, newValues ) => ({...currentValues, ...newValues}), defaultFormValues
    );

    const [localeFormData, setLocaleFormData] = useReducer(
        ( currentValues, newValues ) => ({...currentValues, ...newValues}), defaultFormValues
    );

    const [campaignFormData, setCampaignFormData] = useReducer(
        ( currentValues, newValues ) => ({...currentValues, ...newValues}), {}
    );

    const setFormDataSingle = (name, value) => {
        setPendingChanges(true);
        setFormData({ [name]: value });
    };

    const setCampaignFormDataSingle = (name, value) => {
        setPendingChanges(true);
        setCampaignFormData({ [name]: value });
    };

    const setLocaleFormDataSingle = (name, value) => {
        setPendingChanges(true);
        setLocaleFormData({ [name]: value });
    };

    const setLocaleCreateFormDataSingle = (name, value) => {
        setLocaleCreateFormData({ [name]: value });
    };

    const setTranslationCreateFormDataSingle = (name, value) => {
        setTranslationCreateFormData({ [name]: value });
    };

    const handleFormDataChange = event => {
        const isCheckbox = event.target.type === 'checkbox';
        let value = event.target.value;

        if( value === 'false' )
        {
            value = false;
        }
        else if( value === 'true' )
        {
            value = true;
        }

        setFormDataSingle(
          event.target.name,
          isCheckbox ? event.target.checked : value
        );
    }

    const handleLocaleFormDataChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setLocaleFormDataSingle(
          event.target.name,
          isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handleCampaignFormDataChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setCampaignFormDataSingle(
          event.target.name,
          isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handleLocaleCreateChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setLocaleCreateFormDataSingle(
          event.target.name,
          isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handleTranslationCreateChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setTranslationCreateFormDataSingle(
          event.target.name,
          isCheckbox ? event.target.checked : event.target.value
        );
    }

    
    const [optInAdvancedPermission, setOptInAdvancedPermission] = useState(false);
    
    const [translationSubmitting, setTranslationSubmitting] = useState(false);
    const [localeSubmitting, setLocaleSubmitting] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [pendingChanges, setPendingChanges] = useState(false);

    // Campaign State
    const [countryData, setCountryData] = useState();
    const [countryDataLoading, setCountryDataLoading] = useState(true);
    const [campaignData, setCampaignData] = useState();
    const [campaignDataLoading, setCampaignDataLoading] = useState(true);
    const [recordLabelData, setRecordLabelData] = useState();
    const [recordLabelLoading, setRecordLabelLoading] = useState(true);
    const [fontData, setFontData] = useState();
    const [fontLoading, setFontLoading] = useState(true);
    const [languageData, setLanguageData] = useState();
    const [languageLoading, setLanguageLoading] = useState(true);

    // Campaign Data
    const [campaign, setCampaign] = useState();
    const [campaignLocale, setCampaignLocale] = useState();
    const [campaignTranslation, setCampaignTranslation] = useState();
    const [campaignTranslationDeleteId, setCampaignTranslationDeleteId] = useState(); // Perhaps not the best way to handle this?

    // Download state
    const [downloadData, setDownloadData] = useReducer(
        ( currentValues, newValues ) => ({...currentValues, ...newValues}), {}
    );
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [downloadFileData, setDownloadFileData] = useState();
    const [downloadFileLoading, setDownloadFileLoading] = useState(false);
    
    // Campaign type selector
    const [campaignTypeSelectorExpanded, setCampaignTypeSelectorExpanded] = useState(true);
    const [campaignTypeSelectorTransitioning, setCampaignTypeSelectorTransitioning] = useState(false);

    // Modals
    const [unlockVideoModal, setUnlockVideoModal] = useState(false);
    const [optInExamplesModal, setOptInExamplesModal] = useState(false);

    const [downloadFilesModal, setDownloadFilesModal] = useState(false);
    const [campaignLocaleModal, setCampaignLocaleModal] = useState(false);
    const [campaignLocaleCreateModal, setCampaignLocaleCreateModal] = useState(false);
    const [campaignTranslationModal, setCampaignTranslationModal] = useState(false);
    const [campaignTranslationDeleteModal, setCampaignTranslationDeleteModal] = useState(false);
    const [campaignTranslationCreateModal, setCampaignTranslationCreateModal] = useState(false);

    const userRepository = new UserRepository();
    const translationRepository = new AcquisitionRepository();
    const acquisitionRepository = new AcquisitionRepository();
    const acquisitionAPI = new AcquisitionAPI();

    userRepository.setBearerToken(userDetails.accessToken);
    translationRepository.setBearerToken(userDetails.accessToken);
    acquisitionRepository.setBearerToken(userDetails.accessToken);
    acquisitionAPI.setBearerToken(userDetails.accessToken);

    const setDownloadDataSingle = (name, value) => {
        setPendingChanges(true);
        setDownloadData({ [name]: value });
    };

    const handleDownloadDataChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setDownloadDataSingle(
          event.target.name,
          isCheckbox ? event.target.checked : event.target.value
        );
    }

    const toggleCampaignLocaleModal = event => {
        setCampaignLocaleModal(!campaignLocaleModal);
    }

    const toggleDownloadFilesModal = event => {
        setDownloadFilesModal(!downloadFilesModal);
    }

    const toggleUnlockVideoModal = event => {
        setUnlockVideoModal(!unlockVideoModal);
    }

    const toggleOptInExamplesModal = event => {
        setOptInExamplesModal(!optInExamplesModal);
    }

    const toggleCampaignTranslationDeleteModal = event => {
        setCampaignTranslationDeleteModal(!campaignTranslationDeleteModal);
    }

    const toggleCampaignTranslationModal = event => {
        setCampaignTranslationModal(!campaignTranslationModal);
    }

    const toggleCampaignLocaleCreateModal = event => {
        setCampaignLocaleCreateModal(!campaignLocaleCreateModal);
    }

    const toggleCampaignTranslationCreateModal = event => {
        setCampaignTranslationCreateModal(!campaignTranslationCreateModal);
    }

    const handleSubmit = async event => {
        setSubmitting(true);

        // Handle configuration specific
        Object.keys(defaultCampaignConfigurationFields).forEach(configurationKey => {
            var value = formData[configurationKey];

            // Is this item an array with a corresponding tranlation item?
            if( Array.isArray(defaultCampaignConfigurationFields[configurationKey]) && formData.hasOwnProperty(configurationKey) && value )
            {
                var refinedValue = [];

                // Cycle through each array value for this item
                value.forEach(valueSingle => {
                    var newValue = {};
    
                    // Loop through all of the configuration fields, for this value
                    defaultCampaignConfigurationFields[configurationKey].forEach(fieldName => {
                        if( valueSingle.hasOwnProperty(fieldName) )
                        {
                            newValue[fieldName] = valueSingle[fieldName];
                        }
                    });
    
                    refinedValue.push(newValue);
                });

                value = refinedValue;
            }

            campaignLocale.setConfigurationValue(configurationKey, value);
        });

        // Handle translation specific
        Object.keys(defaultCampaignTranslationFields).forEach(translationKey => {
            var value = formData[translationKey];

            // Is this item an array with a corresponding tranlation item?
            if( Array.isArray(defaultCampaignTranslationFields[translationKey]) && formData.hasOwnProperty(translationKey) && value )
            {
                var refinedValue = [];

                // Cycle through each array value for this item
                value.forEach(valueSingle => {
                    var newValue = {};
    
                    // Loop through all of the configuration fields, for this value
                    defaultCampaignTranslationFields[translationKey].forEach(fieldName => {
                        if( valueSingle.hasOwnProperty(fieldName) )
                        {
                            newValue[fieldName] = valueSingle[fieldName];
                        }
                    });
    
                    refinedValue.push(newValue);
                });

                value = refinedValue;
            }

            campaignTranslation.setTextValue(translationKey, value);
        });

        var existingCountryCodes = [];
        var newCountryCodes = [];

        campaignLocale.getCountries().forEach( element => existingCountryCodes.push(element.getA2()) );

        if( existingCountryCodes.length > 0 )
        {
            await acquisitionAPI.removeCampaignLocaleCountry(campaign.getId(), campaignLocale.getId(), existingCountryCodes);
        }

        if( localeFormData['countries'] && ( localeFormData['countries'].length > 0 ) )
        {
            localeFormData['countries'].forEach( element => newCountryCodes.push(element.getA2()) );

            campaignLocale.setCountries(localeFormData['countries']);
            acquisitionAPI.addCampaignLocaleCountry(campaign.getId(), campaignLocale.getId(), newCountryCodes);
        }

        acquisitionAPI
            .updateCampaign(campaign.getId(), {
                name: campaignFormData['name'],
                urlSlug: campaignFormData['urlSlug']
            })
            .then( updateCampaignResponse => {
                console.log({
                    configuration: campaignLocale.getConfiguration(),
                    dateStart: localeFormData['dateStart'],
                    dateFinish: localeFormData['dateFinish'],
                    uris: localeFormData['uris'],
                    artist: localeFormData['artist'],
                    recordLabel: localeFormData['label'],
                    integrations: localeFormData['integrations'],
                    types: localeFormData['types'],
                    implementations: localeFormData['implementations']
                });
                acquisitionAPI
                    .updateCampaignLocale(campaign.getId(), campaignLocale.getId(), {
                        configuration: campaignLocale.getConfiguration(),
                        dateStart: localeFormData['dateStart'],
                        dateFinish: localeFormData['dateFinish'],
                        uris: localeFormData['uris'],
                        artist: localeFormData['artist'],
                        recordLabel: localeFormData['label'],
                        integrations: localeFormData['integrations'],
                        types: localeFormData['types'],
                        implementations: localeFormData['implementations']
                    })
                    .then(updateCampaignLocaleResponse => {
                        acquisitionAPI
                            .updateCampaignTranslation(campaign.getId(), campaignLocale.getId(), campaignTranslation.getId(), {
                                text: campaignTranslation.getText()
                            })
                            .then(updateCampaignTranslationResponse => {
                                setSubmitting(false);
                                setPendingChanges(false);

                                enqueueSnackbar("Campaign updated", {
                                    variant: 'success'
                                });
                            });
                });
    
            });

    }

    useEffect(() => {
        if( formData['redeemableCode.enabled'] && campaignFormData['id'] )
        {
            setDownloadLoading(true);

            if( campaignFormData['download'] )
            {
                var resolveDownload = acquisitionRepository.getDownload(campaignFormData['download']);
            }
            else
            {
                var resolveDownload = acquisitionRepository.createDownload({
                    'campaign': campaignFormData['id']
                });
            }

            resolveDownload
                .then( download => {
                    setCampaignFormDataSingle('download', download.getId());
    
                    setDownloadData({
                        'maxDownloads' : download.getMaxDownloads(),
                        'maxUses' : download.getMaxUses()
                    });

                    setDownloadLoading(false);

                    setDownloadFileLoading(true);

                    acquisitionRepository.getDownloadFiles(download.getId())
                        .then(downloadFiles => {
                            setDownloadFileData(downloadFiles);
                            setDownloadFileLoading(false);
                        });

                });
        }
    }, [formData['redeemableCode.enabled']]);

    useEffect(() => {
        acquisitionRepository.getCountries({limit: 200})
            .then( countries => {
                var countryData = [];

                countries.getItems().forEach(country => {
                    countryData.push(country);
                });

                setCountryData(countryData);
                setCountryDataLoading(false);
            });

        acquisitionRepository.getRecordLabels({limit: 200})
            .then( recordLabels => {
                var recordLabelOptions = [];

                recordLabels.getItems().forEach(recordLabel => {
                    recordLabelOptions.push({
                        label: recordLabel.getName(),
                        id: recordLabel.getId()
                    });

                    if( recordLabel.getChildRecordLabels() )
                    {
                        recordLabel.getChildRecordLabels().forEach(childRecordLabel => {
                            recordLabelOptions.push({
                                label: ' - '+childRecordLabel.getName(),
                                id: childRecordLabel.getId()
                            });
                        });
                    }
                });

                setRecordLabelData(recordLabelOptions);
                setRecordLabelLoading(false);
            });

        acquisitionRepository.getFonts({limit: 200})
            .then( fonts => {
                setFontData(fonts);
                setFontLoading(false);
            });

        acquisitionRepository.getLanguages({limit: 200})
            .then( languages => {
                setLanguageData(languages);
                setLanguageLoading(false);
            });
        
        userRepository.getUserPermission(userDetails.user.id, 'advancedOptIn')
            .then( userPermission => {
                if( userPermission.getValue() )
                {
                    setOptInAdvancedPermission(userPermission.getValue());
                }
            })
            // User doesn't have this permission
            .catch(error => {

            });
    }, []);

    useEffect(() => {
        translationRepository.getTranslation(3).then(fallbackTranslation => {
            acquisitionRepository.getCampaign(campaignId)
                .then( campaign => {
                    document.title = campaign.getName()+' Stats';

                    var campaignLocale;
                    var campaignTranslation;

                    if( campaignLocaleId )
                    {
                        var campaignLocale = campaign.getLocales().find(campaignLocaleSingle => campaignLocaleSingle.getId() == campaignLocaleId);
                    }
                    else
                    {
                        var campaignLocale = campaign.getDefaultLocale();
                    }

                    if( campaignTranslationId )
                    {
                        var campaignTranslation = campaignLocale.getTranslations().find(campaignTranslationSingle => campaignTranslationSingle.getId() == campaignTranslationId);
                    }
                    else
                    {
                        var campaignTranslation = campaignLocale.getDefaultTranslation();
                    }

                    setCampaign(campaign);
                    setCampaignLocale(campaignLocale);
                    setCampaignTranslation(campaignTranslation);

                    setCampaignTypeSelectorExpanded(campaignLocale.getTypes() ? false : true);

                    var configuration = flattenObject(campaignLocale.getConfiguration());

                    var language = flattenObject(campaignTranslation.getTextValue());
                    var fallbackText = flattenObject(fallbackTranslation.getTextValue());

                    // Delete any properties that shouldn't exit
                    Object.keys(configuration).forEach(valueKey => {
                        if( !defaultCampaignConfigurationFields.hasOwnProperty(valueKey) )
                        {

                            delete configuration[valueKey];
                        }
                    });

                    // Delete any properties that shouldn't exit
                    Object.keys(language).forEach(valueKey => {
                        if( !defaultCampaignTranslationFields.hasOwnProperty(valueKey) )
                        {

                            delete language[valueKey];
                        }
                    });

                    Object.keys(fallbackText).forEach(valueKey => {
                        if( language.hasOwnProperty(valueKey) && !language[valueKey] && ( language[valueKey] !== '' ) )
                        {
                            language[valueKey] = fallbackText[valueKey];
                        }
                        else if( !language.hasOwnProperty(valueKey) )
                        {
                            language[valueKey] = fallbackText[valueKey];
                        }
                    });

                    let formDataNew = {};
                    let localeFormDataNew = {};
                    let campaignFormDataNew = {};

                    campaignFormDataNew['id'] = campaign.getId();
                    campaignFormDataNew['name'] = campaign.getName();
                    campaignFormDataNew['urlSlug'] = campaign.getUrlSlug();
                    campaignFormDataNew['download'] = campaign.getDownload() ? campaign.getDownload().getId() : undefined;

                    localeFormDataNew['dateStart'] = campaignLocale.getDateStart();
                    localeFormDataNew['dateFinish'] = campaignLocale.getDateFinish();
                    localeFormDataNew['artist'] = campaignLocale.getArtist();
                    localeFormDataNew['types'] = campaignLocale.getTypes();
                    localeFormDataNew['implementations'] = campaignLocale.getImplementations();
                    localeFormDataNew['integrations'] = campaignLocale.getIntegrations();
                    localeFormDataNew['label'] = campaignLocale.getRecordLabel() ? campaignLocale.getRecordLabel().getId() : null;
                    localeFormDataNew['countries'] = campaignLocale.getCountries();
                    localeFormDataNew['uris'] = campaignLocale.getURIs();

                    [...Object.keys(configuration), ...Object.keys(language)].forEach(valueKey => {
                        let value;

                        if( language.hasOwnProperty(valueKey) && configuration.hasOwnProperty(valueKey) )
                        {
                            /*if( !['additionalDataForm.fields', 'formEntry.fields'].includes(valueKey) )
                            {
                                value = mergeObjects(language[valueKey], configuration[valueKey], false);
                            }
                            else

                            {
                                value = Array.from(configuration[valueKey], singleValue => {
                                    let matchedTranslation = language[valueKey].find(singleValueTranslation => singleValue.name === singleValueTranslation.name);
                                    
                                    if( matchedTranslation )
                                    {
                                        singleValue.label = matchedTranslation.label;
                                    }
    
                                    return singleValue;
                                });
                            }*/

                            value = mergeObjects(language[valueKey], configuration[valueKey], false);
                        }
                        else if( language.hasOwnProperty(valueKey) )
                        {
                            value = language[valueKey];
                        }
                        else if( configuration.hasOwnProperty(valueKey) )
                        {
                            value = configuration[valueKey];
                        }

                        formDataNew[valueKey] = value;
                    });

                    // Default Values: Start
                    // This might not be the best way to handle this

                    // Integration Data
                    if( !formDataNew['serviceThemes'] )         formDataNew['serviceThemes'] = [];
                    if( !formDataNew['integrationSettings'] )   formDataNew['integrationSettings'] = [];
                    if( !formDataNew['integrationText'] )       formDataNew['integrationText'] = [];

                    if( !formDataNew['brandedButtonTheme.backgroundColor'] ) formDataNew['brandedButtonTheme.backgroundColor'] = '#f4f4f4';
                    if( !formDataNew['brandedButtonTheme.textColor'] )       formDataNew['brandedButtonTheme.textColor'] = '#111111';

                    if( !formDataNew['buttonTheme.backgroundColor'] ) formDataNew['buttonTheme.backgroundColor'] = '#1779ba';
                    if( !formDataNew['buttonTheme.textColor'] )       formDataNew['buttonTheme.textColor'] = '#fefefe';
                    if( !formDataNew['buttonTheme.borderRadius'] )       formDataNew['buttonTheme.borderRadius'] = 0;

                    if( !formDataNew['theme.container.borderRadius'] )       formDataNew['theme.container.borderRadius'] = 0;

                    productFamilies.forEach(productFamily => {
                        var serviceTheme = formDataNew['serviceThemes'] ? formDataNew['serviceThemes'].find(serviceTheme => serviceTheme.id === productFamily.id) : undefined;

                        if( !serviceTheme )
                        {
                            serviceTheme = {
                                id: productFamily.id,
                                textColor: productFamily.defaultTextColor,
                                backgroundColor: productFamily.defaultBackgroundColor
                            };
                            
                            formDataNew['serviceThemes'].push(serviceTheme);
                        }
                    });

                    if( campaignLocale.getIntegrations() && ( campaignLocale.getIntegrations().length > 0 ) )
                    {
                        campaignLocale.getIntegrations().forEach(integration => {
                            var oldLabel = formDataNew['integrations.'+integration+'.text.default'];
                            var oldBackgroundColor = formDataNew['integrations.'+integration+'.backgroundColor'];
                            var oldTextColor = formDataNew['integrations.'+integration+'.textColor'];

                            let intgrationDefault = {...supportedIntegrations.find(supportedIntegration => supportedIntegration.id === integration)};

                            let productFamily = productFamilies.find(productFamiliesSingle => productFamiliesSingle.id === intgrationDefault.productFamily);

                            var serviceTheme = formDataNew['serviceThemes'] ? formDataNew['serviceThemes'].find(serviceTheme => serviceTheme.id === productFamily.id) : undefined;
                            var integrationText = formDataNew['integrationText'] ? formDataNew['integrationText'].find(integrationText => integrationText.id === integration) : undefined;

                            if( !serviceTheme )
                            {
                                serviceTheme = {
                                    id: integration,
                                    textColor: oldTextColor || productFamily.defaultTextColor,
                                    backgroundColor: oldBackgroundColor || productFamily.defaultBackgroundColor
                                };
                                
                                formDataNew['serviceThemes'].push(serviceTheme);
                            }

                            if( !integrationText )
                            {
                                integrationText = {
                                    id: integration,
                                    label: oldLabel || intgrationDefault.label
                                };

                                formDataNew['integrationText'].push(integrationText);
                            }
                        });

                    }

                    // Pre Save
                    if( !formDataNew['preSave.email.artistName'] )      formDataNew['preSave.email.artistName'] = localeFormData['artist'];
                    if( !formDataNew['preSave.email.trackName'] )       formDataNew['preSave.email.trackName'] = formData['general.context'];

                    if( !formDataNew['preSave.countdown.layout'] )    formDataNew['preSave.countdown.layout'] = 'both';

                    // Default Values: End

                    setCampaignFormData(campaignFormDataNew);
                    setFormData(formDataNew);
                    setLocaleFormData(localeFormDataNew);

                    setCampaignData(campaign);
                    setCampaignDataLoading(false);
                });
            });
    }, [campaignLocaleId, campaignTranslationId]);

    let productFamilyList = [];
    let integrations = [];

    if( localeFormData['integrations'] && ( localeFormData['integrations'].length > 0 ) )
    {
        localeFormData['integrations'].forEach(integration => {
            let intgrationDefault = {...supportedIntegrations.find(supportedIntegration => supportedIntegration.id === integration)};

            var integrationSettings = formData['integrationSettings'] ? formData['integrationSettings'].find(integrationSetting => integrationSetting.id === integration) : undefined;
            var integrationText = formData['integrationText'] ? formData['integrationText'].find(integrationText => integrationText.id === integration) : undefined;

            if( integrationSettings )
            {
                Object.keys(integrationSettings).forEach(integrationSettingKey => {
                    intgrationDefault[integrationSettingKey] = integrationSettings[integrationSettingKey];
                });
            }

            if( integrationText )
            {
                Object.keys(integrationText).forEach(integrationTextKey => {
                    intgrationDefault[integrationTextKey] = integrationText[integrationTextKey];
                });
            }

            integrations.push(intgrationDefault);

            if( !productFamilyList.find(productFamily => productFamily.id === intgrationDefault.productFamily) )
            {
                var productFamilyData = {...productFamilies.find(productFamiliesSingle => productFamiliesSingle.id === intgrationDefault.productFamily)};

                productFamilyList.push(productFamilyData);
            }
        });

    }

    var tabData = [];

    tabData.push({
        label: 'Setup',
        content: <>
            <Accordion header="Basic information" subHeader="Tell us about this campaign.">
                <Fieldset>

                    <FieldContainer xs={12}>
                        <InputLabel shrink htmlFor="name">Campaign Name <Tooltip title={<>The fan will never see this, it's purely for internal use to best identify your campaign.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <TextField
                            id="name"
                            name="name"
                            fullWidth
                            value={campaignFormData['name'] || ''}
                            onChange={handleCampaignFormDataChange}
                        />
                    </FieldContainer>
                    

                    <FieldContainer>
                        <InputLabel shrink htmlFor="artist">Campaign Artist <Tooltip title={<>If this campaign pertains to an artist then enter that artists name here, for instance <strong>"Jason Derulo"</strong>. This is used internally for statistics and record keeping.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <TextField
                            id="artist"
                            name="artist"
                            fullWidth
                            value={localeFormData['artist'] || ''}
                            onChange={handleLocaleFormDataChange}
                        />
                    </FieldContainer>

                    <FieldContainer>
                        <InputLabel shrink htmlFor="label">Campaign Label <Tooltip title={<>For internal use only. Please select the correct label for this campaign. This information is used for data collection and, aside from admins, only members of this record label will be able to make changes to and view stats for this campaign. If you can\'t find the label you\'re looking for then please email <MLink to="mailto:warner-connect-support@wmg.com">warner-connect-support@wmg.com</MLink>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                        { recordLabelData &&
                            <Select
                                id="label"
                                name="label"
                                onChange={handleLocaleFormDataChange}
                                value={localeFormData['label'] || ''}
                                fullWidth
                            >
                                <MenuItem value="">None</MenuItem>

                                {recordLabelData.map((recordLabel) => (
                                    <MenuItem key={recordLabel.id} value={recordLabel.id}>{recordLabel.label}</MenuItem>
                                ))}
                            </Select>
                        }
                    </FieldContainer>

                    <FieldContainer>
                        <InputLabel shrink htmlFor="general.genre">Campaign Genre <Tooltip title={<>All users entering this campaign will be submitted to ExactTarget with this genre as their genre preference. This is used for better targeting when mailing users. This should be the genre you feel most accurately represent the artist, playlist or theme of this campaign.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                        { exactTargetGenres &&
                            <Select
                                id="general.genre"
                                name="general.genre"
                                onChange={handleFormDataChange}
                                value={formData['general.genre'] || ''}
                                fullWidth
                            >
                                <MenuItem value="">None</MenuItem>

                                {exactTargetGenres.map((recordLabel) => (
                                    <MenuItem key={recordLabel.id} value={recordLabel.id}>{recordLabel.label}</MenuItem>
                                ))}
                            </Select>
                        }
                    </FieldContainer>

                    <FieldContainer>
                        <InputLabel shrink htmlFor="general.context">Campaign Album/Playlist <Tooltip title={<>If the user follows a playlist or album when they enter the campaign then enter the name of that playlist here, for instance: <strong>"Topsify UK Top 50"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                        <TextField
                            id="general.context"
                            name="general.context"
                            fullWidth
                            value={formData['general.context'] || ''}
                            onChange={handleFormDataChange}
                            placeholder="e.g. Topsify UK Top 50"
                        />
                    </FieldContainer>

                    <FieldContainer>
                        <InputLabel shrink htmlFor="urlSlug">Short URL <Tooltip title={<>This will be the used for the last section of the campaign URL.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                        <TextField
                            id="urlSlug"
                            name="urlSlug"
                            fullWidth
                            value={campaignFormData['urlSlug'] || ''}
                            onChange={handleCampaignFormDataChange}
                            InputProps={{
                                startAdornment: <InputAdornment sx={{opacity: '0.5', mr: 0}} position="start">{`${process.env.REACT_APP_FRONTEND_URL}/`}</InputAdornment>,
                            }}
                        />
                    </FieldContainer>

                </Fieldset>
            </Accordion>

            <Accordion header="What to follow" subHeader="Paste the DSP URLs you'd like users entering this campaign to follow or subscribe to." sx={{minHeight: '300px', backgroundColor: 'rgba(70, 190, 230, .1)', backgroundPosition: 'right 30px top 65px', backgroundImage: 'url('+FormContainerUriBackgroundImage+')', backgroundRepeat: 'no-repeat'}}>
                <Fieldset>
                    <FieldContainer>

                        <InputLabel shrink htmlFor="uris">Uris <Tooltip title={<>This is a list of URIs & URLs that users entering this campaign will follow, subscribe to or have added to their library.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <UriList
                            name="uris"
                            value={localeFormData['uris'] || null}
                            onChange={(newValue) => {
                                setLocaleFormDataSingle('uris', newValue);
                            }}
                        />
                    </FieldContainer>

                </Fieldset>
            </Accordion>

            <Accordion header="Campaign Entry" subHeader="Select what services to enable your users to enter the campaign with.">
                <Fieldset>
                <FieldContainer xs={12}>
                        <Services
                            name="integrations"
                            onChange={(newValue) => {
                                setLocaleFormDataSingle('integrations', Array.from(newValue.filter(value => value.active), integration => integration.id));

                                setFormDataSingle('integrationText', Array.from(newValue, integration => {
                                    return {
                                        id: integration.id,
                                        label: integration.label
                                    };
                                }));

                                setFormDataSingle('integrationSettings', Array.from(newValue, integration => {
                                    let existingIntegrationSetting = formData['integrationSettings'] ? formData['integrationSettings'].find(integrationSetting => integrationSetting.id === integration.id) : undefined;

                                    if( existingIntegrationSetting )
                                    {
                                        existingIntegrationSetting.link = integration.link;
                                    }
                                    else
                                    {
                                        existingIntegrationSetting = {
                                            id: integration.id,
                                            link: integration.link
                                        };
                                    }

                                    return existingIntegrationSetting;
                                }));
                            }}
                            value={integrations}
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Form Entry <Tooltip title={<>Allow users to enter this campaign using a manual form entry.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <FormControlLabel control={<Switch name="formEntry.enabled" onChange={handleFormDataChange} checked={formData['formEntry.enabled'] ? true : false} />} label={formData['formEntry.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>

                    { formData['formEntry.enabled'] &&
                        <>
                            <FieldContainer>
                                <InputLabel shrink htmlFor="formEntry.entryButton.text">Button Text <Tooltip title={<>Text to prompt user to fill in the alternate entry form. Leave blank to default to <strong>"Not on Spotify? Enter here."</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    name="formEntry.entryButton.text"
                                    fullWidth
                                    placeholder="Not on Spotify? Enter here."
                                    value={formData['formEntry.entryButton.text'] || ''}
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Display by Default? <Tooltip title={<>If you'd like the Alternate Entries form to display automatically without the user having to click the call-to-action, then set this field to <strong>"Yes"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <RadioGroup row name="formEntry.displayByDefault" value={formData['formEntry.displayByDefault'] || false} onChange={handleFormDataChange}>
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <FormBuilderFields name="formEntry.fields" onChange={(newValue) => { setFormDataSingle('formEntry.fields', newValue); }} value={formData['formEntry.fields']} />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink htmlFor="formEntry.backButton.text">Back Button Text <Tooltip title={<>Text for back button on alternate entry form. Defaults to <strong>"Back"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    name="formEntry.backButton.text"
                                    fullWidth
                                    placeholder="Back"
                                    value={formData['formEntry.backButton.text'] || ''}
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink htmlFor="formEntry.submitButton.text">Submit Button Text <Tooltip title={<>Text for enter button on alternate entry form. Defaults to <strong>"Enter Competition"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    name="formEntry.submitButton.text"
                                    fullWidth
                                    placeholder="Enter Competition"
                                    value={formData['formEntry.submitButton.text'] || ''}
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>
{
    /*

                            <FieldContainer xs={12}><
                                <InputLabel shrink>Facebook Enabled? <Tooltip title={<>If you'd like to allow alternate entries via <strong>Facebook</strong> then set this to <strong>"Yes"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <FormControlLabel control={<Switch name="formEntry.facebook.enabled" onChange={handleFormDataChange} checked={formData['formEntry.facebook.enabled'] ? true : false} />} label={formData['formEntry.facebook.enabled'] ? "Enabled" : "Disabled"} />
                            </FieldContainer>

                            { formData['formEntry.facebook.enabled'] &&
                                <>
                                    <FieldContainer xs={6}>
                                        <InputLabel shrink>Facebook Button Prompt Text? <Tooltip title={<>This text is displayed before the Facebook CTA inviting the user to fill in the form or enter with Facebook.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <TextField
                                            name="formEntry.facebook.label.text"
                                            fullWidth
                                            placeholder="Fill in the form below or"
                                            value={formData['formEntry.facebook.label.text'] || ''}
                                            onChange={handleFormDataChange}
                                        />
                                    </FieldContainer>

                                    <FieldContainer xs={6}>
                                        <InputLabel shrink>Facebook Button Text? <Tooltip title={<>This is the text that appears on the Facebook CTA.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <TextField
                                            name="formEntry.facebook.button.text"
                                            fullWidth
                                            placeholder="Enter with Facebook"
                                            value={formData['formEntry.facebook.button.text'] || ''}
                                            onChange={handleFormDataChange}
                                        />
                                    </FieldContainer>
                                </>
                            }

                            
    */
}
                        </>
                    }
                </Fieldset>
            </Accordion>
            <Accordion header="When & Where" subHeader="Select where your campaigns will be broadcasted & the dates when it should be live.">
                <Fieldset>

                    <FieldContainer xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <InputLabel shrink>Date Starting <Tooltip title={<>This is purely for reference and should represent the date the campaign will be starting.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                            <DesktopDatePicker
                                name="dateStart"
                                inputFormat="dd/MM/yyyy"
                                maxDate={localeFormData['dateFinish'] || null}
                                value={localeFormData['dateStart'] || ''}
                                onChange={(newValue) => {
                                    setLocaleFormDataSingle('dateStart', newValue);
                                }}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                    </FieldContainer>

                    <FieldContainer xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <InputLabel shrink>Date Finish <Tooltip title={<>This is purely for reference and should represent the date the competition will stop running. The <strong>"Competition End"</strong> fields should still be used to trigger the "competition closed" message.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                            <DesktopDatePicker
                                name="dateFinish"
                                inputFormat="dd/MM/yyyy"
                                minDate={localeFormData['dateStart'] || null}
                                value={localeFormData['dateFinish'] || ''}
                                onChange={(newValue) => {
                                    setLocaleFormDataSingle('dateFinish', newValue);
                                }}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Territories <Tooltip title={<>Select the territories in which this campaign will be running. Leave blank if this is a global campaign.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        {
                            countryData &&
                                <CountrySelector
                                    name="countries"
                                    value={localeFormData['countries']}
                                    options={countryData}
                                    onChange={(event, newValue) => {
                                        setLocaleFormDataSingle('countries', newValue);
                                    }}
                                />
                        }
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <Divider />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Close Campaign When Finished?</InputLabel>
                        <FormControlLabel control={<Switch name="close.enabled" onChange={handleFormDataChange} checked={formData['close.enabled'] ? true : false} />} label={formData['close.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>

                    { formData['close.enabled'] &&
                        <>
                            <FieldContainer xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <InputLabel shrink>Close Date & Time <Tooltip title={<>This is The date & time the campaign will end. This time is always represented in your local time zone but automatically applies to other time zones, relative to your time zone. For instance; if your time zone is UTC/GMT+!0 and you choose 11PM as the close time, the cmapaign will close at 1PM for a user in a UTC/GMT time zone.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                    <DateTimePicker
                                        value={formData['close.dateTime']}
                                        onChange={(newValue) => {
                                            setFormDataSingle('close.dateTime', newValue);
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </FieldContainer>

                            <FieldContainer xs={12}>

                                <RichTextEditor
                                    value={formData['close.content']}
                                    onEditorChange={(value) => {
                                        setFormDataSingle('close.content', value);
                                    }}
                                    name="close.content"
                                />
                            </FieldContainer>

                        </>
                    }
                </Fieldset>
            </Accordion>

            <Accordion header="Blocked Territories" subHeader="Users from selected territories will be unable to view this campaign.">
                <Fieldset>
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Blocked Territories</InputLabel>
                        <FormControlLabel control={<Switch name="blockedCountries.enabled" onChange={handleFormDataChange} checked={formData['blockedCountries.enabled'] ? true : false} />} label={formData['blockedCountries.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>
                    { formData['blockedCountries.enabled'] &&
                        <>
                            <FieldContainer xs={12}>
                                <InputLabel shrink>Territories</InputLabel>
                                {
                                    ( countryData && campaignData ) &&
                                        <CountrySelector
                                            name="blockedCountries.countries"
                                            value={countryData.filter( e => {
                                                return formData['blockedCountries.countries'] ? formData['blockedCountries.countries'].indexOf(e.getA2()) > -1 : null
                                            })}
                                            options={countryData}
                                            onChange={(event, newValue) => {
                                                var newA2Values = [];

                                                newValue.forEach(valueSingle => {
                                                    newA2Values.push(valueSingle.getA2());
                                                });

                                                setFormDataSingle('blockedCountries.countries', newA2Values);
                                            }}
                                        />
                                }
                            </FieldContainer>
                            
                            <FieldContainer xs={12}>
                                <InputLabel shrink htmlFor="blockedCountries.title">Title <Tooltip title={<>Title text for the "Blocked" page.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    name="blockedCountries.title"
                                    fullWidth
                                    value={formData['blockedCountries.title'] || ''}
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink htmlFor="blockedCountries.content">Message <Tooltip title={<>Message text for the "Blocked" page.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <RichTextEditor
                                    value={formData['blockedCountries.content']}
                                    onEditorChange={(value) => {
                                        setFormDataSingle('blockedCountries.content', value);
                                    }}
                                    name="blockedCountries.content"
                                />
                            </FieldContainer>
                        </>
                    }

                </Fieldset>
            </Accordion>

            <Accordion header="Security" subHeader="Additional settings to make sure your landing page is protected.">
                <Fieldset>
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Password Protection</InputLabel>
                        <FormControlLabel control={<Switch name="password.enabled" onChange={handleFormDataChange} checked={formData['password.enabled'] ? true : false} />} label={formData['password.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>
                    { formData['password.enabled'] &&
                        <>
                            <FieldContainer xs={12}>
                                <InputLabel shrink htmlFor="password.key">Password</InputLabel>
                                <TextField
                                    name="password.key"
                                    fullWidth
                                    type="password"
                                    value={formData['password.key'] || ''}
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink htmlFor="password.title">Title <Tooltip title={<>Title text for the "Password" page.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    name="password.title"
                                    fullWidth
                                    value={formData['password.title'] || ''}
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink htmlFor="password.content">Message <Tooltip title={<>Message text for the "Password" page.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <RichTextEditor
                                    value={formData['password.content']}
                                    onEditorChange={(value) => {
                                        setFormDataSingle('password.content', value);
                                    }}
                                    name="password.content"
                                />
                            </FieldContainer>
                        </>
                    }
                </Fieldset>
            </Accordion>

        </>
    });
    
    tabData.push({
        label: 'Content',
        content: <>
            <Accordion header="Layout" subHeader="Choose exactly how you want your landing page to look.">
                <Grid container>
                    <Grid item xs={5}>
                        <Fieldset>
                            <FieldContainer xs={12}>
                                <InputLabel shrink>Desktop Position <Tooltip title={<>How would you like your content to be positioned?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                
                                <RadioGroupLinear
                                    value={formData['theme.layout.themePosition'] || 'center_center'}
                                    name="theme.layout.themePosition"
                                    onChange={handleFormDataChange}
                                >
                                    <Radio value="center_center" title="Center" />
                                    <Radio value="full" title="Full Screen (Beta)" />
                                </RadioGroupLinear>
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink>Desktop Layout <Tooltip title={<>How would you like your page to be laid out?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                
                                <RadioGroupLinear
                                    value={formData['theme.layout.themeStyle'] || 'wide_description_right'}
                                    name="theme.layout.themeStyle"
                                    onChange={handleFormDataChange}
                                >
                                    <Radio value="wide_description_right" title="Description Right" />
                                    <Radio value="wide_description_left" title="Description Left" />
                                    <Radio value="thin" label="Narrow" />
                                    <Radio value="wide_normal" label="Wide" />
                                </RadioGroupLinear>
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink>Button Style <Tooltip title={<>How would you like your buttons to look?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                
                                <RadioGroupLinear
                                    value={formData['theme.layout.buttonStyle'] || 'standard'}
                                    name="theme.layout.buttonStyle"
                                    onChange={handleFormDataChange}
                                >
                                    <Radio value="standard" title="Standard" />
                                    <Radio value="full" title="Full" />
                                </RadioGroupLinear>
                            </FieldContainer>
                            
                            {
                                formData['theme.layout.buttonStyle'] !== 'full' &&
                                    <FieldContainer xs={12}>
                                        <InputLabel shrink>Button Size <Tooltip title={<>How would you like your buttons to look?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        
                                        <RadioGroupLinear
                                            value={formData['theme.layout.buttonSize'] || 'medium'}
                                            name="theme.layout.buttonSize"
                                            onChange={handleFormDataChange}
                                        >
                                            <Radio value="small" title="Small" />
                                            <Radio value="medium" title="Medium" />
                                            <Radio value="large" title="Large" />
                                            <Radio value="xlarge" title="Extra Large" />
                                        </RadioGroupLinear>
                                    </FieldContainer>
                            }
                            
                            <FieldContainer xs={12}>
                                <InputLabel shrink>Button Position <Tooltip title={<>How would you like your buttons to be positioned?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                
                                <RadioGroupLinear
                                    value={formData['theme.layout.buttonPosition'] || 'below_description'}
                                    name="theme.layout.buttonPosition"
                                    onChange={handleFormDataChange}
                                >
                                    <Radio value="below_description" title="Buttons Below Description" />
                                    <Radio value="above_description" title="Buttons Above Description" />
                                </RadioGroupLinear>
                            </FieldContainer>

                            {
                                formData['theme.layout.buttonStyle'] === 'full' &&

                                    <FieldContainer xs={12}>
                                        <InputLabel shrink>Button Position Style <Tooltip title={<>How would you like your buttons to be positioned?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        
                                        <RadioGroupLinear
                                            value={formData['theme.layout.buttonPositionStyle'] || 'inside'}
                                            name="theme.layout.buttonPositionStyle"
                                            onChange={handleFormDataChange}
                                        >
                                            <Radio value="inside" title="Inside" />
                                            <Radio value="offset" title="Offset" />
                                        </RadioGroupLinear>
                                    </FieldContainer>
                            }

                        </Fieldset>

                    </Grid>

{/* 
                    buttonStyle: 'full', //'full' or 'standard'
    buttonSize: 'large', //Defaults to 'small', //'medium' //'large'//'x-large'
    buttonPosition: '',//defaults to below //'above_description',
    buttonPositionStyle: '', //defaults to inside  //'offset'
    // buttonPositionStyle: 'offset', // offset location
    buttonModal: true,
    buttonModalText: '',// defaults to 'follow' // 'presave'
    buttonModalCloseText: 'close in spanish',
    buttonModalColor: '',//defaults to '#46BEE6'
    buttonModalStyle: '',//default 'full', //'standard' candy bar rectangle buttons
    buttonHideText: true,
    buttonOfficialBrandingEnabled: false,
    buttonBrandingImage: '', // URL if using custom image**
*/}
                    <Grid item xs={7} pt={2}>
                        <LayoutPreview
                            themePosition={formData['theme.layout.themePosition'] || undefined}
                            buttonStyle={formData['theme.layout.buttonStyle'] || undefined}
                            buttonSize={formData['theme.layout.buttonSize'] || undefined}
                            themeStyle={formData['theme.layout.themeStyle'] || undefined}
                            buttonPosition={formData['theme.layout.buttonPosition'] || undefined}
                        />
                    </Grid>
                </Grid>
            </Accordion>

            <Accordion header="Images" subHeader="Choose which images you'd like to use with your campaign.">
                <Fieldset>                    
                    <FieldContainer xs={4}>
                        <InputLabel shrink>Header <Tooltip title={<>If you'd like an image at the top of the page, add that here.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        
                        <ImageUpload
                            value={formData['theme.headerImage.url'] || ''}
                            onChange={(value) => {
                                setFormDataSingle('theme.headerImage.url', value);
                            }}
                        />
                    </FieldContainer>
                    
                    <FieldContainer xs={4}>
                        <InputLabel shrink>Hero <Tooltip title={<>This image will appear to the left, right or above the "Description" depending on the template configuration. Usually this will be an image of an album, playlist or artist. Should be no bigger than <strong>500x500</strong> with a file-size no greater than <strong>500Kb</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        
                        <ImageUpload
                            value={formData['theme.image.url'] || ''}
                            onChange={(value) => {
                                setFormDataSingle('theme.image.url', value);
                            }}

                        />
                    </FieldContainer>

                    <FieldContainer xs={4}>
                        <InputLabel shrink>Background <Tooltip title={<>The background image for the page. Should be no bigger than <strong>1500x1500</strong> with a file-size no greater than <strong>1Mb</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        
                        <ImageUpload
                            value={formData['theme.background.image.url'] || ''}
                            onChange={(value) => {
                                setFormDataSingle('theme.background.image.url', value);
                            }}
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <Divider />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Mobile Only Images <Tooltip title={<>If you'd like to use mobiel specific images, you can uplaod them here.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <FormControlLabel
                            disabled={formData['theme.mobile.headerImage.url'] || formData['theme.mobile.image.url'] || formData['theme.mobile.background.image.url'] ? true : false}
                            control={
                                <Switch name="_.theme.images.mobile.enabled"
                                    onChange={handleFormDataChange}
                                    checked={(formData['_.theme.images.mobile.enabled'] || ( formData['theme.mobile.headerImage.url'] || formData['theme.mobile.image.url'] || formData['theme.mobile.background.image.url'] )) ? true : false}
                                />
                            }
                            label={(formData['_.theme.images.mobile.enabled'] || ( formData['theme.mobile.headerImage.url'] || formData['theme.mobile.image.url'] || formData['theme.mobile.background.image.url'] )) ? "Enabled" : "Disabled"}
                        />
                    </FieldContainer>

                    { ( formData['_.theme.images.mobile.enabled'] || ( formData['theme.mobile.headerImage.url'] || formData['theme.mobile.image.url'] || formData['theme.mobile.background.image.url'] ) ) &&
                        <>
                            <FieldContainer xs={4}>
                                <InputLabel shrink>Header <Tooltip title={<>If you'd like an image at the top of the page, add that here.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                
                                <ImageUpload
                                    value={formData['theme.mobile.headerImage.url'] || ''}
                                    onChange={(value) => {
                                        setFormDataSingle('theme.mobile.headerImage.url', value);
                                    }}        
                                />
                            </FieldContainer>
                            
                            <FieldContainer xs={4}>
                                <InputLabel shrink>Hero <Tooltip title={<>This image will appear to the left, right or above the "Description" depending on the template configuration. Usually this will be an image of an album, playlist or artist. Should be no bigger than <strong>500x500</strong> with a file-size no greater than <strong>500Kb</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                
                                <ImageUpload
                                    value={formData['theme.mobile.image.url'] || ''}
                                    onChange={(value) => {
                                        setFormDataSingle('theme.mobile.image.url', value);
                                    }}
                                />
                            </FieldContainer>

                            <FieldContainer xs={4}>
                                <InputLabel shrink>Background <Tooltip title={<>The background image for the page. Should be no bigger than <strong>1500x1500</strong> with a file-size no greater than <strong>1Mb</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                
                                <ImageUpload
                                    value={formData['theme.mobile.background.image.url'] || ''}
                                    onChange={(value) => {
                                        setFormDataSingle('theme.mobile.background.image.url', value);
                                    }}
                                />
                            </FieldContainer>
                        </>
                    }

                </Fieldset>
            </Accordion>

            <Accordion header="Text content" subHeader="Enter the text you'd like to appear on the landing page.">
                <Fieldset>                    
                    <FieldContainer xs={12}>
                        <InputLabel shrink htmlFor="landingPage.title">Title <Tooltip title={<>This is the title for the campaign, which will be displayed on the page, above the description. Even if you're not creating a page with a landing page it's still useful to have a human readable title for your campaign.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <TextField
                            placeholder="e.g. Win Tickets to see Ed Sheeran Live in London"
                            id="landingPage.title"
                            name="landingPage.title"
                            fullWidth
                            value={formData['landingPage.title'] || ''}
                            onChange={handleFormDataChange}
                        />
                    </FieldContainer>
                    
                    <FieldContainer xs={6}>
                        <InputLabel shrink>Description <Tooltip title={<>This field appears in the body of the page & should be a short description informing the user about this campaign.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        
                        <RichTextEditor
                            value={formData['landingPage.content'] || ''}
                            onEditorChange={(value) => {
                                setFormDataSingle('landingPage.content', value);
                            }}
                            name="landingPage.content"
                        />
                    </FieldContainer>

                    <FieldContainer xs={6}>
                        <InputLabel shrink>Bottom Description <Tooltip title={<>Option text that appears below the campaign\'s main image & description.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        
                        <RichTextEditor
                            value={formData['landingPage.bottomContent']}
                            onEditorChange={(value) => {
                                setFormDataSingle('landingPage.bottomContent', value);
                            }}
                            name="landingPage.bottomContent"
                        />
                    </FieldContainer>

                    <FieldContainer xs={6}>
                        <InputLabel shrink>Meta Title <Tooltip title={<>This is the title for the campaign, which will be displayed on both the campaign website and Twitter & Facebook posts and search engine results. If no value is given then the campaigns <strong>"Title"</strong> field will be used.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        
                        <TextField
                            placeholder="e.g. Win Tickets to see Ed Sheeran Live in London"
                            value={formData['general.meta.title'] || ''}
                            name="general.meta.title"
                            fullWidth
                            onChange={handleFormDataChange}
                        />
                    </FieldContainer>

                    <FieldContainer xs={6}>
                        <InputLabel shrink>Meta Description <Tooltip title={<>This is the description for the campaign, which will be displayed on both Twitter & Facebook posts and used in search engine results. If no text is entered then the campaign\'s main <strong>"Description"</strong> will be used in this field.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        
                        <TextField
                            placeholder="e.g. Want to see Ed Sheeran in London but missed out on the pre-sale? Enter for your chance to win two tickets, so you and a friend can see Ed Sheeran LIVE at the O2 Arena this August!"
                            value={formData['general.meta.description'] || ''}
                            name="general.meta.description"
                            multiline
                            rows={4}
                            fullWidth
                            onChange={handleFormDataChange}
                        />
                    </FieldContainer>

                </Fieldset>
            </Accordion>

            <Accordion header="Thank you message" subHeader="Enter the text you'd like to appear after a user has entered a campaign, thanking them for their participation.">
                <Fieldset>                    
                    <FieldContainer xs={6}>
                        <InputLabel shrink htmlFor="successPage.title">Thank You Title <Tooltip title={<>If you'd like to display a different title on the "Thank You" page then you can enter that here. For instance, with a pre-save campaign you could have the thank you message <strong>"Thank you for Pre-Saving Divide by Ed Sheeran"</strong>, rather than the main title which might be <strong>"Pre Save Divide by Ed Sheeran"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <TextField
                            placeholder="e.g. Thank you for Pre-Saving Divide by Ed Sheeran"
                            id="successPage.title"
                            name="successPage.title"
                            fullWidth
                            value={formData['successPage.title'] || ''}
                            onChange={handleFormDataChange}
                        />
                    </FieldContainer>
                    
                    <FieldContainer xs={6}>
                        <InputLabel shrink htmlFor="successPage.uris">URIs <Tooltip title={<>Add YouTube, Spotify, Deezer, Apple Music or Amazon Music URLs/URIs if you\'d like an embedded piece of content to be presented to the user upon them entering the competition. The video will be shown below the "Thank You Message". If you add a URL/URI for several different DSPs then the user will be given the URL that applies to the DSP they signed in with. For instance, if a user logged in with Spotify, then they will be presented with the Spotify URL/URI. If only a YouTube URL is provided then they will be shown the video .</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <UriList
                            name="successPage.uris"
                            value={formData['successPage.uris'] || null}
                            onChange={(newValue) => {
                                setFormDataSingle('successPage.uris', newValue);
                            }}
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink htmlFor="successPage.content">Thank You Message <Tooltip title={<>A short message, given to the user after they have entered the campaign. For a Pre-Save this could be "<strong>Thanks for Pre-Saving Divide By Ed Sheeran. Why not listen to his playlist below.</strong>".</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <RichTextEditor
                            value={formData['successPage.content']}
                            onEditorChange={(value) => {
                                setFormDataSingle('successPage.content', value);
                            }}
                            name="successPage.content"
                        />
                    </FieldContainer>

                </Fieldset>
            </Accordion>

            <Accordion header="Social" subHeader="Enable social sharing for this campaign.">
                <Fieldset>                    
                    
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Social Sharing <Tooltip title={<>Upon the user signing up to the competition, would you like them to be prompted to share to Facebook & Twitter?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <FormControlLabel control={<Switch name="social.enabled" onChange={handleFormDataChange} checked={formData['social.enabled'] ? true : false} />} label={formData['social.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>

                    { formData['social.enabled'] &&
                        <>
                            <FieldContainer xs={12}>
                                <Divider />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <Alert severity="warning" sx={{mb: 2}}>Facebook sharing currently isn't available but we're working with Facebook to get this functionality working again</Alert>

                                <InputLabel shrink>Facebook</InputLabel>
                                <FormControlLabel control={<Switch name="social.facebook.enabled" onChange={handleFormDataChange} checked={formData['social.facebook.enabled'] ? true : false} />} label={formData['social.facebook.enabled'] ? "Enabled" : "Disabled"} />
                            </FieldContainer>

                            { formData['social.facebook.enabled'] &&
                                <>

                                    <FieldContainer xs={6}>
                                        <InputLabel shrink>Facebook Button Text <Tooltip title={<>This is the text that will appear on the button prompting the user to share the campaign to Facebook. By default it will read <strong>"Share to Facebook"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <TextField
                                            value={formData['social.facebook.button.text'] || ''}
                                            name="social.facebook.button.text"
                                            placeholder="Share to Facebook"
                                            fullWidth
                                            onChange={handleFormDataChange}
                                        />
                                    </FieldContainer>

                                    <FieldContainer xs={6}>
                                        <InputLabel shrink>Facebook Image <Tooltip title={<>This image will appear on Facebook when the campaign link is shared. The ideal dimensions for this image are 1200 x 630.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <ImageUpload
                                            value={formData['social.facebook.image.url'] || ''}
                                            onChange={(value) => {
                                                setFormDataSingle('social.facebook.image.url', value);
                                            }}                
                                        />
                                    </FieldContainer>

                                </>
                            }

                            <FieldContainer xs={12}>
                                <Divider />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink>Twitter</InputLabel>
                                <FormControlLabel control={<Switch name="social.twitter.enabled" onChange={handleFormDataChange} checked={formData['social.twitter.enabled'] ? true : false} />} label={formData['social.twitter.enabled'] ? "Enabled" : "Disabled"} />
                            </FieldContainer>

                            { formData['social.twitter.enabled'] &&
                                <>
                                    <FieldContainer xs={6}>
                                        <Fieldset sx={{p: 0}}>
                                            <FieldContainer xs={12}>
                                                <InputLabel shrink>Twitter Button Text <Tooltip title={<>This is the text that will appear on the button prompting the user to share the campaign to Twitter. By default it will read <strong>"Share to Twitter"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                <TextField
                                                    value={formData['social.twitter.button.text'] || ''}
                                                    name="social.twitter.button.text"
                                                    placeholder="Share to Twitter"
                                                    fullWidth
                                                    onChange={handleFormDataChange}
                                                />
                                            </FieldContainer>

                                            <FieldContainer xs={12}>
                                                <InputLabel shrink>Twitter Text <Tooltip title={<>If "Social Sharing" has been set to "Yes" then this is the message that will be shared to the user\'s Twitter timeline. The campaign URL will automatically be appended to the end of this message.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                <TextField
                                                    value={formData['social.twitter.text'] || ''}
                                                    name="social.twitter.text"
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    onChange={handleFormDataChange}
                                                />
                                            </FieldContainer>
                                        </Fieldset>
                                    </FieldContainer>

                                    <FieldContainer xs={6}>
                                        <InputLabel shrink>Twitter Image <Tooltip title={<>This image will be used in Twitter card. If no image is provided then the campaign's main image will be used.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <ImageUpload
                                            value={formData['social.twitter.image.url'] || ''}
                                            onChange={(value) => {
                                                setFormDataSingle('social.twitter.image.url', value);
                                            }}                
                                        />
                                    </FieldContainer>

                                </>
                            }

                        </>

                    }
                </Fieldset>
            </Accordion>

            <Accordion header="Footer links" subHeader="Enter custom social links that will appear in the footer.">
                <Fieldset>                    

                    {
                        campaignFooterLinks
                            .map( (footerLink, footerLinkIndex) => (
                                <FieldContainer xs={6} key={footerLinkIndex}>
                                    <InputLabel shrink>{footerLink.label}</InputLabel>
                                    <TextField
                                        value={formData['footer.buttons.'+footerLink.id+'.url'] || ''}
                                        name={`footer.buttons.${footerLink.id}.url`}
                                        disabled={!(formData['footer.buttons.'+footerLink.id+'.enabled'] ? true : false)}
                                        fullWidth
                                        onChange={handleFormDataChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment sx={{opacity: !(formData['footer.buttons.'+footerLink.id+'.enabled'] || false) ? .38 : 1}} position="start">
                                                    {footerLink.icon}
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Switch name={`footer.buttons.${footerLink.id}.enabled`} onChange={handleFormDataChange} checked={formData['footer.buttons.'+footerLink.id+'.enabled'] ? true : false} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FieldContainer>
                            ))
                    }

                    <FieldContainer xs={12}>
                        <Divider />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Custom Footer Link</InputLabel>
                        <FormControlLabel control={<Switch name="footer.buttons.custom.enabled" onChange={handleFormDataChange} checked={formData['footer.buttons.custom.enabled'] ? true : false} />} label={formData['footer.buttons.custom.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>

                    { formData['footer.buttons.custom.enabled'] &&
                        <>
                            <FieldContainer xs={6}>
                                <InputLabel shrink>Custom Link <Tooltip title={<>If you'd like to add an additional image to the list of footer images then you can enter a link and image.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    value={formData['footer.buttons.custom.url'] || ''}
                                    name="footer.buttons.custom.url"
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Custom Link Image <Tooltip title={<>Will sit on the footer at the bottom, in line with the social sharing icons. Should be <strong>500x500</strong> with a file-size no greater than <strong>500Kb</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <ImageUpload
                                    value={formData['footer.buttons.custom.image.url'] || ''}
                                    onChange={(value) => {
                                        setFormDataSingle('footer.buttons.custom.image.url', value);
                                    }}
                                />
                            </FieldContainer>
                        </>
                    }
                    
                    <FieldContainer xs={12}>
                        <Divider />
                    </FieldContainer>

                    <FieldContainer xs={6}>
                        <InputLabel shrink>Footer Links <Tooltip title={<>Terms & Conditions & Privacy Policy links are added to the footer automatically. You can use this field if you\'d like to replace these links with custom text.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                        <RichTextEditor
                            value={formData['footer.links'] || ''}
                            onEditorChange={(value) => {
                                setFormDataSingle('footer.links', value);
                            }}
                            name="footer.links"
                            size="small"
                        />
                    </FieldContainer>

                </Fieldset>
            </Accordion>

            <Accordion header="Customize Terms and Conditions" subHeader={<>By default the "Terms & Conditions" links will point to <MLink to="https://wminewmedia.com/t&c/" target="_blank">https://wminewmedia.com/t&c/</MLink>.<br />If you'd like to use custom Terms & Conditions you can paste the text into the below field or upload a PDF.</>}>
                <Fieldset>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Custom Terms & Conditions</InputLabel>
                        <FormControlLabel control={<Switch name="termsAndConditions.enabled" onChange={handleFormDataChange} checked={formData['termsAndConditions.enabled'] ? true : false} />} label={formData['termsAndConditions.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>

                    { formData['termsAndConditions.enabled'] &&
                        <>
                            <FieldContainer xs={12}>
                                <InputLabel shrink>File <Tooltip title={<>Upload a PDF.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <FileUpload
                                    value={formData['termsAndConditions.file'] || ''}
                                    onChange={(value) => {
                                        setFormDataSingle('termsAndConditions.file', value);
                                    }}
                                />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink>Title <Tooltip title={<>This title appears at the top of your terms and conditions.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    value={formData['termsAndConditions.title'] || ''}
                                    name="termsAndConditions.title"
                                    onChange={handleFormDataChange}
                                    fullWidth
                                />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink htmlFor="termsAndConditions.content">Content</InputLabel>
                                <RichTextEditor
                                    value={formData['termsAndConditions.content'] || ''}
                                    onEditorChange={(value) => {
                                        setFormDataSingle('termsAndConditions.content', value);
                                    }}
                                    name="termsAndConditions.content"
                                />
                            </FieldContainer>
                        </>
                    }
                </Fieldset>
            </Accordion>
{/*
            <Accordion header="Customize Privacy Policy" subHeader={<>By default the "Privacy Policy" links will point to <MLink to="https://wminewmedia.com/privacy/" target="_blank">https://wminewmedia.com/privacy/</MLink>.<br />If you'd like to use a custom Privacy Policy you can paste the text into this field.</>}>
                <Fieldset>
                    
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Custom Privacy Policy</InputLabel>
                        <FormControlLabel control={<Switch name="privacyPolicy.enabled" onChange={handleFormDataChange} checked={formData['privacyPolicy.enabled'] ? true : false} />} label={formData['privacyPolicy.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>

                    { formData['privacyPolicy.enabled'] &&
                        <>
                            <FieldContainer xs={12}>
                                <InputLabel shrink>File <Tooltip title={<>Upload a PDF.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <FileUpload
                                    value={formData['privacyPolicy.file'] || ''}
                                    onChange={(value) => {
                                        setFormDataSingle('privacyPolicy.file', value);
                                    }}
                                />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink>Title <Tooltip title={<>This title appears at the top of your privacy policy.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    value={formData['privacyPolicy.title'] || ''}
                                    name="privacyPolicy.title"
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink htmlFor="privacyPolicy.content">Content</InputLabel>
                                <RichTextEditor
                                    value={formData['privacyPolicy.content'] || ''}
                                    onEditorChange={(value) => {
                                        setFormDataSingle('privacyPolicy.content', value);
                                    }}
                                    name="privacyPolicy.content"
                                />
                            </FieldContainer>
                        </>
                    }
                </Fieldset>
            </Accordion>
    
            <Accordion header="Customize Terms of Use" subHeader={<>By default the "Terms of Use" links will point to <MLink to="https://wminewmedia.com/terms-of-use/" target="_blank">https://wminewmedia.com/terms-of-use/</MLink>.<br />If you'd like to use a custom Terms of Use you can paste the text into this field.</>}>
                <Fieldset>
                    
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Custom Terms of Use</InputLabel>
                        <FormControlLabel control={<Switch name="termsOfUse.enabled" onChange={handleFormDataChange} checked={formData['termsOfUse.enabled'] ? true : false} />} label={formData['termsOfUse.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>

                    { formData['termsOfUse.enabled'] &&
                        <>
                            <FieldContainer xs={12}>
                                <InputLabel shrink>File <Tooltip title={<>Upload a PDF.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <FileUpload
                                    value={formData['termsOfUse.file'] || ''}
                                    onChange={(value) => {
                                        setFormDataSingle('termsOfUse.file', value);
                                    }}
                                />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink>Title <Tooltip title={<>This title appears at the top of your terms of use.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    value={formData['termsOfUse.title'] || ''}
                                    name="termsOfUse.title"
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink htmlFor="termsOfUse.content">Content</InputLabel>
                                <RichTextEditor
                                    value={formData['termsOfUse.content'] || ''}
                                    onEditorChange={(value) => {
                                        setFormDataSingle('termsOfUse.content', value);
                                    }}
                                    name="termsOfUse.content"
                                />
                            </FieldContainer>
                        </>
                    }
                </Fieldset>
            </Accordion>
*/}
            <Accordion header="Advanced" subHeader={<>Only use this section if you're confident that you know what you're doing as it could cause drastic problems for your campaign.</>}>
                <Fieldset>
                    <FieldContainer xs={12}>
                        <FormControlLabel control={<Switch name="advanced.enabled" onChange={handleFormDataChange} checked={formData['advanced.enabled'] ? true : false} />} label={formData['advanced.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>

                    { formData['advanced.enabled'] &&
                        <>
                            <FieldContainer xs={12}>
                                <InputLabel shrink htmlFor="advanced.additionalStyles">Inline CSS <Tooltip title={<>You can add additional CSS code here.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    value={formData['advanced.additionalStyles'] || ''}
                                    name="advanced.additionalStyles"
                                    multiline
                                    rows={8}
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink htmlFor="advanced.additionalScripts">Additional Scripts <Tooltip title={<>You can add additional JavaScript code here. Please refrain from adding any additional tracking code as this needs to be done via <strong>Adobe Launch</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    value={formData['advanced.additionalScripts'] || ''}
                                    name="advanced.additionalScripts"
                                    multiline
                                    rows={8}
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>
                        </>
                    }
                </Fieldset>
            </Accordion>
        </>
    });

    tabData.push({
        label: 'Audience',
        content: <>
            <Accordion light header="Opt-In Dialog" subHeader="Choose if you want your opt-in dialog to show up as a separate pop up or directly on the page you're creating">
                <Fieldset>                    
                    <FieldContainer xs={12}>
                        <ImageRadio
                            value={formData['optIn.showOnLandingPage'] === true ? 'in-line' : 'pop-up'}
                            states={[
                                {
                                    value: 'pop-up',
                                    label: 'Pop Up',
                                    active: OptInDialoguePopupActive,
                                    inactive: OptInDialoguePopup
                                },
                                {
                                    value: 'in-line',
                                    label: 'In-Line',
                                    active: OptInDialogueInlineActive,
                                    inactive: OptInDialogueInline
                                }
                            ]}
                            onChange={(newValue) => {
                                setFormDataSingle('optIn.showOnLandingPage', newValue === 'in-line' ? true : false);
                            }}
                        />
                    </FieldContainer>

                </Fieldset>
            </Accordion>

            <Accordion light header="Opt-In Format" subHeader={<>If you're creating a simple sign up form without prizes or incentives then you can<br />select the "Simple Sign Up Option".<br /><br />If there's a prize/giveaway element to your campaign you need to select "Competition Consent",<br />otherwise you can use "Non-Competition Consent".</>}>
                <Fieldset>
                    <FieldContainer xs={12}>
                        <ImageRadio
                            value={formData['optIn.format'] || ''}
                            states={[
                                {
                                    value: '',
                                    label: 'Competition Consent',
                                    active: OptInTypeComplexActive,
                                    inactive: OptInTypeComplex
                                },
                                {
                                    value: 'simple_consent',
                                    label: 'Non-Competition Consent',
                                    active: OptInTypeSimpleActive,
                                    inactive: OptInTypeSimple
                                },
                                {
                                    value: 'simple_sign_up',
                                    label: 'Simple Sign Up',
                                    active: OptInTypeSignUpActive,
                                    inactive: OptInTypeSignUp
                                }
                            ]}
                            onChange={(newValue) => {
                                setFormDataSingle('optIn.format', newValue);
                            }}
                        />
                    </FieldContainer>

                </Fieldset>
            </Accordion>

            <Accordion header="Opt-In Set Up" subHeader={<>These are the mailing list opt-in prompts will be displayed.</>}>
                <Grid container>
                    <Grid sm={12}>
                        <Alert severity="info">
                            <Typography sx={{pb: 1}}>Add the email lists you want to direct the acquisitions to. The text can not be edited but if a translation is required this can be added the language module.<br /><br />If you want to add default translated language for your respective territory contact <MLink target="_blank" href="mailto:warner-connect-support@wmg.com">warner-connect-support@wmg.com</MLink>.</Typography>
                        </Alert>
                    </Grid>

                    <Grid item sm={6}>
                        <Fieldset>
                        { 
                            // Full Consent
                            ( formData['optIn.format'] === '' ) &&
                                <>
                                    <FieldContainer xs={12}>
                                        <OptIn
                                            override={optInAdvancedPermission}
                                            value={formData['optIn.fullConsent.lists'] || null}
                                            onChange={(newValue) => {
                                                setFormDataSingle('optIn.fullConsent.lists', newValue);
                                            }}
                                        />
                                    </FieldContainer>

                                    <FieldContainer xs={12}>
                                        <InputLabel shrink htmlFor="optIn.fullConsent.entryButton.text">Entry Button Text <Tooltip title={<>This is the text that appears on the button, alongside the opt-in options where the users agrees to our Terms & Conditions. By default this text reads "<strong>Enter</strong>".</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <TextField
                                            value={formData['optIn.fullConsent.entryButton.text'] || ''}
                                            name="optIn.fullConsent.entryButton.text"
                                            placeholder="Enter"
                                            fullWidth
                                            onChange={handleFormDataChange}
                                        />
                                    </FieldContainer>

                                    <FieldContainer xs={12}>
                                        <InputLabel shrink htmlFor="optIn.fullConsent.error.text">Error Message <Tooltip title={<>If you the user clicks button to enter the competition & they haven't agreed to the "<strong>Terms & Conditions</strong>" they will shown an error prompting them to agree to the Terms & Conditions before proceeding. By default this text reads "<strong>Please agree to the Terms & Conditions to continue</strong>".</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <TextField
                                            value={formData['optIn.fullConsent.error.text'] || ''}
                                            name="optIn.fullConsent.error.text"
                                            placeholder="Please agree to the Terms & Conditions to continue"
                                            fullWidth
                                            onChange={handleFormDataChange}
                                        />
                                    </FieldContainer>

                                </>
                        }
                        {
                            // Simple Consent
                            ( formData['optIn.format'] === 'simple_consent' ) &&
                                <>
                                    <FieldContainer xs={12}>
                                        <OptIn
                                            value={formData['optIn.simpleConsent.lists'] || null }
                                            onChange={(newValue) => {
                                                setFormDataSingle('optIn.simpleConsent.lists', newValue);
                                            }}
                                        />
                                    </FieldContainer>

                                    <FieldContainer xs={12}>
                                        <InputLabel shrink htmlFor="optIn.simpleConsent.entryButton.text">Entry Button Text <Tooltip title={<>This is the text that appears on the button, alongside the opt-in options where the users agrees to our Terms & Conditions. By default this text reads "<strong>Enter</strong>".</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <TextField
                                            value={formData['optIn.simpleConsent.entryButton.text'] || ''}
                                            name="optIn.simpleConsent.entryButton.text"
                                            placeholder="Enter"
                                            fullWidth
                                            onChange={handleFormDataChange}
                                        />
                                    </FieldContainer>

                                    <FieldContainer xs={12}>
                                        <InputLabel shrink htmlFor="optIn.simpleConsent.error.text">Error Message <Tooltip title={<>If you the user clicks button to enter the competition & they haven't agreed to the "<strong>Terms & Conditions</strong>" they will shown an error prompting them to agree to the Terms & Conditions before proceeding. By default this text reads "<strong>Please agree to the Terms & Conditions to continue</strong>".</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <TextField
                                            value={formData['optIn.simpleConsent.error.text'] || ''}
                                            name="optIn.simpleConsent.error.text"
                                            placeholder="Please agree to the Terms of Use to continue"
                                            fullWidth
                                            onChange={handleFormDataChange}
                                        />
                                    </FieldContainer>
                                </>
                        }
                        {
                            // Simple Sign Up
                            ( formData['optIn.format'] === 'simple_sign_up' ) &&
                                <>
                                    <FieldContainer xs={12}>
                                        {<OptIn
                                            value={formData['optIn.simpleSignUp.lists'] || null}
                                            simple={true}
                                            onChange={(newValue) => {
                                                setFormDataSingle('optIn.simpleSignUp.lists', newValue);
                                            }}
                                        />}
                                    </FieldContainer>

                                    <FieldContainer xs={12}>
                                        <InputLabel shrink htmlFor="optIn.simpleSignUp.label">Entry Button Label <Tooltip title={<>This is the text that appears above the sign up button.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <RichTextEditor
                                            value={formData['optIn.simpleSignUp.label'] || null}
                                            onEditorChange={(value) => {
                                                setFormDataSingle('optIn.simpleSignUp.label', value);
                                            }}
                                            fullWidth
                                            size="medium"
                                            name="optIn.simpleSignUp.label"
                                        />
                                    </FieldContainer>

                                    <FieldContainer xs={12}>
                                        <InputLabel shrink htmlFor="optIn.simpleSignUp.entryButton.text">Entry Button Text <Tooltip title={<>This is the text that appears on the button, alongside the opt-in options where the users agrees to our Terms & Conditions. By default this text reads "<strong>Enter</strong>".</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <TextField
                                            value={formData['optIn.simpleSignUp.entryButton.text'] || 'Enter'}
                                            name="optIn.simpleSignUp.entryButton.text"
                                            placeholder="Enter"
                                            fullWidth
                                            onChange={handleFormDataChange}
                                        />
                                    </FieldContainer>
                                    
                                </>
                                    
                        }

                        
                            <FieldContainer xs={12}>
                                <InputLabel shrink htmlFor="optIn.footer.text">Privacy Policy Text <Tooltip title={<>This text appears below the enter button and explains WMG's privacy policy.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <RichTextEditor
                                    size="auto"
                                    value={formData['optIn.footer.text'] || '<p>Please see our <a href="http://www.wminewmedia.com/privacy/" target="_blank" rel="noopener">Privacy Policy</a> for more information about how Warner Music Group companies handle your data. You can opt out anytime by clicking the unsubscribe in the email.</p>'}
                                    onEditorChange={(value) => {
                                        setFormDataSingle('optIn.footer.text', value);
                                    }}
                                />
                            </FieldContainer>
                        </Fieldset>

                    </Grid>
                    <Grid item sm={6}>

                    </Grid>
                </Grid>

            </Accordion>

            <Accordion header="User Must By 18+" subHeader="Enable this option if you'd like to ask all users signing in with a DSP or 3rd party service to confirm theit age to ensure they're 18 years of age or older.">
                <Fieldset>                    
                    <FieldContainer xs={12}>
                        <InputLabel shrink htmlFor="optInSettings.ageConstraintEnabled">User Must be 18+ to Enter <Tooltip title={<>If you select this option, users will need to confirm their date of birth makes them 18 years of age or older.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <RadioGroup row name="optInSettings.ageConstraintEnabled" value={formData['optInSettings.ageConstraintEnabled'] ? true : false} onChange={handleFormDataChange}>
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FieldContainer>
                    
                    {
                        formData['optInSettings.ageConstraintEnabled'] && <>
                            <FieldContainer xs={6}>
                                <InputLabel shrink htmlFor="optInSettings.ageConstraintLabel">Field label <Tooltip title={<>This is the text that appears next to the field.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    value={formData['optInSettings.ageConstraintLabel'] || 'Enter your date of birth'}
                                    name="optInSettings.ageConstraintLabel"
                                    placeholder="Enter"
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink htmlFor="optInSettings.ageConstraintError">Message displayed if they enter a date of birth that's too low</InputLabel>
                                <TextField
                                    value={formData['optInSettings.ageConstraintError']}
                                    name="optInSettings.ageConstraintError"
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>
                        </>
                    }
                </Fieldset>
            </Accordion>

            <Accordion header="Acquisition Data">
                <Fieldset>
                    <FieldContainer xs={6}>
                        <InputLabel shrink htmlFor="optInSettings.disableAppleMusicDataCapture">Disable Apple Music Additional Data Capture <Tooltip title={<>When users enter using Apple Music we don\'t get access to their personal details such as their email & name. By default we ask the user to enter this data manually, but this can be disabled by setting this field to <strong>"Yes"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <RadioGroup row name="optInSettings.disableAppleMusicDataCapture" value={formData['optInSettings.disableAppleMusicDataCapture'] ? true : false} onChange={handleFormDataChange}>
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FieldContainer>

                    <FieldContainer xs={6}>
                        <InputLabel shrink htmlFor="optIn.appleMusicPaidAccountError">Apple Music Paid Account Error <Tooltip title={<>This text appears when a user tries to sign in with an Apple Music account that is not a paid account. Unfortauntely it is not possible to sign in with a free Apple Music account.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <RichTextEditor
                            size="auto"
                            value={formData['optIn.appleMusicPaidAccountError']}
                            onEditorChange={(value) => {
                                setFormDataSingle('optIn.appleMusicPaidAccountError', value);
                            }}
                        />
                    </FieldContainer>

                    <FieldContainer xs={6}>
                        <InputLabel shrink htmlFor="optInSettings.dataSource">ExactTarget Datasource <Tooltip title={<>Data Sources are automatically created. If you'd like to use a custom Datasource then you can enter one here.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <TextField
                            value={formData['optInSettings.dataSource']}
                            name="optInSettings.dataSource"
                            placeholder={campaignLocale ? campaignLocale.getCDCDataSource() : "e.g. WBR_GreenDay_RevolutionRadio_PreSave"}
                            fullWidth
                            onChange={handleFormDataChange}
                        />
                    </FieldContainer>

                </Fieldset>
            </Accordion>

        </>
    });

    if( localeFormData['types'] && localeFormData['types'].includes('redeemabledownloadcode') )
    {
        tabData.push({
            label: 'Redeemable Code',
            content: <>
                <Accordion header="Redeemable Code" subHeader={<>Add the ability to distribute codes to users that can be entered to claim a reward, such as a digital download.</>}>
                    <Fieldset>
                        <FieldContainer xs={12}>
                            <FormControlLabel control={<Switch name="redeemableCode.enabled" onChange={handleFormDataChange} checked={formData['redeemableCode.enabled'] ? true : false} />} label={formData['redeemableCode.enabled'] ? "Enabled" : "Disabled"} />
                        </FieldContainer>

                        { formData['redeemableCode.enabled'] &&
                            <>
                                { downloadLoading &&
                                    <FieldContainer xs={6}>
                                        <Typography>Loading...</Typography>
                                    </FieldContainer>
                                
                                }
                                { !downloadLoading &&
                                    <>
                                        <FieldContainer xs={6}>
                                            <InputLabel shrink htmlFor="redeemableCode.method">Artist Name <Tooltip title={<>This is the artist name that will be sent to the user in their automated email, for instance <strong>"Jason Derulo"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                            <Select
                                                value={formData['redeemableCode.method'] || undefined}
                                                name="redeemableCode.method"
                                                fullWidth
                                                onChange={handleFormDataChange}
                                            >
                                                {
                                                    [
                                                        {value: 'browser_download', label: 'Download in Browser'},
                                                    ].map( (item) => (
                                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                                    ))
                                                }

                                            </Select>
                                        </FieldContainer>

                                        <FieldContainer xs={6}>
                                            <InputLabel shrink htmlFor="redeemableCode.fieldLabel.text">Field Label <Tooltip title={<>This is the label of the field asking the user to enter their code.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                            <TextField
                                                value={formData['redeemableCode.fieldLabel.text'] || undefined}
                                                name="redeemableCode.fieldLabel.text"
                                                placeholder={'Redeemable Code'}
                                                fullWidth
                                                onChange={handleFormDataChange}
                                            />
                                        </FieldContainer>

                                        <FieldContainer xs={6}>
                                            <InputLabel shrink htmlFor="redeemableCode.error.text">Error Message <Tooltip title={<>The message shown to the user upon entering an incorrect code.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                            <TextField
                                                value={formData['redeemableCode.error.text'] || undefined}
                                                name="redeemableCode.error.text"
                                                placeholder={'Your redemption code hasn\'t been recognised or is no longer valid.'}
                                                fullWidth
                                                onChange={handleFormDataChange}
                                            />
                                        </FieldContainer>

                                        <FieldContainer xs={6}>
                                            <InputLabel shrink htmlFor="redeemableCode.successMessage.text">Success Message <Tooltip title={<>Thank you, your code has been accepted and you can download your files below.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                            <TextField
                                                value={formData['redeemableCode.successMessage.text'] || undefined}
                                                name="redeemableCode.successMessage.text"
                                                placeholder={'Thank you, your code has been accepted and you can download your files below.'}
                                                fullWidth
                                                onChange={handleFormDataChange}
                                            />
                                        </FieldContainer>

                                        <FieldContainer xs={6}>
                                            <InputLabel shrink htmlFor="redeemableCode.button.text">Download Button Text <Tooltip title={<>The text on the button prompting the user to download the redeemed files.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                            <TextField
                                                value={formData['redeemableCode.button.text'] || undefined}
                                                name="redeemableCode.button.text"
                                                placeholder={'Download Button'}
                                                fullWidth
                                                onChange={handleFormDataChange}
                                            />
                                        </FieldContainer>
                                    </>
                                }
                            </>
                        }

                    </Fieldset>
                </Accordion>

                { ( formData['redeemableCode.enabled'] && !downloadLoading && downloadData ) &&
                    <Accordion header="Downloadable Files" subHeader={<>Below are the files users will be able to download after entering a valid code & are typically ZIP files.</>}>
                        <Fieldset>

                            <FieldContainer xs={6}>
                                <InputLabel shrink htmlFor="maxUses">Max Uses <Tooltip title={<>How many times can each code be used?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    value={downloadData['maxUses'] || undefined}
                                    type="number"
                                    name="maxUses"
                                    placeholder="0"
                                    fullWidth
                                    onChange={handleDownloadDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink htmlFor="maxDownloads">Max Downloads <Tooltip title={<>How many times can each file be downloaded by each user?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    value={downloadData['maxDownloads'] || undefined}
                                    name="maxDownloads"
                                    type="number"
                                    placeholder="0"
                                    fullWidth
                                    onChange={handleDownloadDataChange}
                                />
                            </FieldContainer>

                            {
                                !downloadFileLoading &&
                                    <>
                                        <FieldContainer xs={12}>
                                            <InputLabel shrink>Add File</InputLabel>
                                            {
                                                ( downloadFileData && downloadFileData.getTotal() === 0 ) &&
                                                    <Card sx={{backgroundColor: '#f4f4f4', p: 1}}>
                                                        <CardActions>
                                                            <Typography variant="body1">No file selected</Typography>
                                                        </CardActions>
                                                    </Card>
                                            }
                                            {
                                                downloadFileData &&
                                                <>
                                                    <Box  sx={{p: 2}}>
                                                        <Grid spacing={2} container>
                                                            <Grid item xs={8}>
                                                                Name
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                File Name
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                {
                                                    downloadFileData.getItems().map( downloadFile => {
                                                        let downloadFileName = downloadFile.getName();
                                                        return (
                                                            <Card key={downloadFile.getId()} sx={{backgroundColor: '#f4f4f4', p: 2}}>
                                                                <Grid spacing={2} container>
                                                                    <Grid item xs={8}>
                                                                        <TextField
                                                                            value={downloadFileName}
                                                                            placeholder={downloadFile.getLocation().split('/').pop()}
                                                                            fullWidth
                                                                            size="small"
                                                                            onBlur={e => {
                                                                                acquisitionRepository.updateDownloadFile(campaignFormData['download'], downloadFile.getId(), {
                                                                                    name: e.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            placeholder={downloadFile.getLocation().split('/').pop()}
                                                                            fullWidth
                                                                            disabled={true}
                                                                            size="small"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Card>
                                                        );
                                                    })
                                                }
                                                </>

                                            }
                                            <Card sx={{mt: 1}}>
                                                <CardActions>
                                                    <Button onClick={toggleDownloadFilesModal} startIcon={<CloudUploadIcon />}>
                                                    Upload
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </FieldContainer>


                                    </>
                            }
                        </Fieldset>
                    </Accordion>
                }

                { ( formData['redeemableCode.enabled'] && campaignFormData['download'] ) &&
                    <Accordion header="Download Codes" subHeader={<>These are the codes that can be used to claim rewards.</>}>
                        <DownloadCodeForm downloadId={campaignFormData['download']} />
                    </Accordion>
                }
            </>
        });
    }

    if( localeFormData['types'] && localeFormData['types'].includes('presave') )
    {
        tabData.push({
            label: 'Pre-Save',
            content: <>

                <Accordion header="Basic Setup" subHeader={<>You can currently create Pre-Save campaigns for Spotify, Apple Music & Deezer.<br /><br />Pre-Save URIs must be added at least 1 day before the track or album is released. Instant Grat URIs can be added at any point during the course of the campaign and will be added to a user's library almost immediately.</>}>
                    <Fieldset>
                        <FieldContainer xs={12}>
                            <FormControlLabel control={<Switch name="preSave.enabled" onChange={handleFormDataChange} checked={formData['preSave.enabled'] ? true : false} />} label={formData['preSave.enabled'] ? "Enabled" : "Disabled"} />
                        </FieldContainer>

                        { formData['preSave.enabled'] &&
                            <>
                                <FieldContainer xs={6}>
                                    <InputLabel shrink htmlFor="preSave.uris">URIs <Tooltip title={<>This is the track or album URI/URL that will be added to the users library on the day of release. This field must be filled in at least 1 day before the release date.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                    <UriList
                                        name="preSave.uris"
                                        value={formData['preSave.uris'] || null}
                                        onChange={(newValue) => {
                                            setFormDataSingle('preSave.uris', newValue);
                                        }}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={6}>
                                    <InputLabel shrink htmlFor="preSave.instantGratUris">Instant Grat URIs <Tooltip title={<>List of instant grat URIs separated by new lines.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                    <UriList
                                        name="preSave.instantGratUris"
                                        value={formData['preSave.instantGratUris'] || null}
                                        onChange={(newValue) => {
                                            setFormDataSingle('preSave.instantGratUris', newValue);
                                        }}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={6}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <InputLabel htmlFor="preSave.releaseDate" shrink>Release Date <Tooltip title={<>Any text entered into this field will replace the <strong>"Pre-Save Release Date"</strong> when viewing the campaign. This can be used for translation and date format changes, for instance <strong>"Released 25th May"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                        <DesktopDatePicker
                                            name="preSave.releaseDate"
                                            inputFormat="dd/MM/yyyy"
                                            value={formData['preSave.releaseDate'] || undefined}
                                            onChange={(newValue) => {
                                                setFormDataSingle('preSave.releaseDate', newValue);
                                            }}
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FieldContainer>

                                <FieldContainer xs={6}>
                                    <InputLabel shrink htmlFor="preSave.releaseDateText">Release Date Text <Tooltip title={<>The date that either the album is released or the first track in the album will be released.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                    <TextField
                                        value={formData['preSave.releaseDateText'] || ''}
                                        name="preSave.releaseDateText"
                                        fullWidth
                                        onChange={handleFormDataChange}
                                    />
                                </FieldContainer>

                            </>
                        }
                    </Fieldset>
                </Accordion>

                { formData['preSave.enabled'] &&
                <>
                    <Accordion header="Countdown Timer" subHeader={<>Display a countdown timer showing how long it is until the music is released.</>}>
                        <Fieldset>
                            <FieldContainer xs={6}>
                                <InputLabel shrink htmlFor="preSave.releaseDateText">Countdown <Tooltip title={<>Display a countdown timer showing how long it is until the music is released</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <FormControlLabel control={<Switch name="preSave.countdown.enabled" onChange={handleFormDataChange} checked={formData['preSave.countdown.enabled'] ? true : false} />} label={formData['preSave.countdown.enabled'] ? "Enabled" : "Disabled"} />
                            </FieldContainer>

                            { formData['preSave.countdown.enabled'] &&

                                <FieldContainer xs={6}>
                                    <InputLabel shrink htmlFor="preSave.countdown.layout">Countdown Position</InputLabel>

                                    <Select
                                        onChange={handleFormDataChange}
                                        value={formData['preSave.countdown.layout'] || 'both'}
                                        name="preSave.countdown.layout"
                                        fullWidth
                                    >
                                        {
                                            [
                                                {value: 'both', label: 'Show Release Date & Countdown'},
                                                {value: 'countdown', label: 'Show Countdown Only'}
                                            ].map( (item) => (
                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FieldContainer>
                            }
                        </Fieldset>
                    </Accordion>

                    <Accordion header="Release Day Email" subHeader={<>Send users a reminder on the day a single or album is released.</>}>
                        <Fieldset>
                            <FieldContainer xs={12}>
                                <FormControlLabel control={<Switch name="preSave.email.enabled" onChange={handleFormDataChange} checked={formData['preSave.email.enabled'] ? true : false} />} label={formData['preSave.email.enabled'] ? "Enabled" : "Disabled"} />
                            </FieldContainer>

                            { formData['preSave.email.enabled'] &&
                                <>
                                    <FieldContainer xs={6}>
                                        <InputLabel shrink htmlFor="preSave.email.artistName">Artist Name <Tooltip title={<>This is the artist name that will be sent to the user in their automated email, for instance <strong>"Jason Derulo"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <TextField
                                            value={formData['preSave.email.artistName'] || undefined}
                                            name="preSave.email.artistName"
                                            placeholder={'e.g. Jason Derulo'}
                                            fullWidth
                                            onChange={handleFormDataChange}
                                        />
                                    </FieldContainer>

                                    <FieldContainer xs={6}>
                                        <InputLabel shrink htmlFor="preSave.email.trackName">Track/Album Name <Tooltip title={<>This is the track or album name that will be sent to the user in their automated email, for instance <strong>"Swalla"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <TextField
                                            value={formData['preSave.email.trackName'] || undefined}
                                            name="preSave.email.trackName"
                                            placeholder={'e.g. Swalla'}
                                            fullWidth
                                            onChange={handleFormDataChange}
                                        />
                                    </FieldContainer>

                                    <FieldContainer xs={6}>
                                        <InputLabel shrink htmlFor="preSave.email.image.url">Image <Tooltip title={<>This will be the image that is sent to the user as part of the automated email. If no image is provided here the campaign's main <strong>"Image"</strong> will be used instead.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <ImageUpload
                                            value={formData['preSave.email.image.url'] || ''}
                                            onChange={(value) => {
                                                setFormDataSingle('preSave.email.image.url', value);
                                            }}
                                        />
                                    </FieldContainer>
                                </>
                            }

                        </Fieldset>
                    </Accordion>

                    { formData['preSave.email.enabled'] &&
                        <Accordion header="Reminder Email" subHeader={<>Send fans who entered the pre save but haven't listened to the track or album yet a reminder, just in case they missed the first email. (Spotify users only).</>}>
                            <Fieldset>
                                <FieldContainer xs={12}>
                                    <FormControlLabel control={<Switch name="preSave.emailReminder.enabled" onChange={handleFormDataChange} checked={formData['preSave.emailReminder.enabled'] ? true : false} />} label={formData['preSave.emailReminder.enabled'] ? "Enabled" : "Disabled"} />
                                </FieldContainer>

                                { formData['preSave.emailReminder.enabled'] &&
                                    <>
                                        <FieldContainer xs={6}>
                                            <InputLabel shrink htmlFor="preSave.emailReminder.daysAfterRelease">When do you Want to Send the Reminder <Tooltip title={<>How many days after the album/single release do you want to send users who haven't yet listented to the album/single and email reminder?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                            <Select
                                                onChange={handleFormDataChange}
                                                value={formData['preSave.emailReminder.daysAfterRelease'] || undefined}
                                                name="preSave.emailReminder.daysAfterRelease"
                                                fullWidth
                                            >
                                                {
                                                    [
                                                        {value: 3, label: '3 days after release'},
                                                        {value: 4, label: '4 days after release'},
                                                        {value: 5, label: '5 days after release'},
                                                        {value: 6, label: '6 days after release'},
                                                        {value: 7, label: '7 days after release'},
                                                        {value: 8, label: '8 days after release'},
                                                        {value: 9, label: '9 days after release'},
                                                        {value: 10, label: '10 days after release'},
                                                        {value: 11, label: '11 days after release'},
                                                        {value: 12, label: '12 days after release'},
                                                        {value: 13, label: '13 days after release'},
                                                        {value: 14, label: '14 days after release'},
                                                    ].map( (item) => (
                                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                                    ))
                                                }

                                            </Select>
                                        </FieldContainer>
                                    </>
                                }

                            </Fieldset>
                        </Accordion>
                    }
                    </>
                }
            </>
        });
    }

    
    //if( localeFormData['types'] && ( localeFormData['types'].includes('contentunlock') || localeFormData['types'].includes('presave') ) )
    //{
        tabData.push({
            label: 'Content Unlock',
            content: <>

                <Accordion header="Basic Setup" subHeader={<>Unlock a piece of content when the user enters the campaign.</>}>
                    <Fieldset>
                        <FieldContainer xs={12}>
                            <FormControlLabel control={<Switch name="contentUnlock.enabled" onChange={handleFormDataChange} checked={formData['contentUnlock.enabled'] ? true : false} />} label={formData['contentUnlock.enabled'] ? "Enabled" : "Disabled"} />
                        </FieldContainer>
                    </Fieldset>
                </Accordion>

                { formData['contentUnlock.enabled'] &&
                    <>
                        <Accordion header="Service Link" subHeader={<>The user unlocks a YouTube video upon entering the campaign.</>}>
                            <Fieldset>
                                <FieldContainer xs={12}>
                                    <FormControlLabel control={<Switch name="contentUnlock.link.enabled" onChange={handleFormDataChange} checked={formData['contentUnlock.link.enabled'] ? true : false} />} label={formData['contentUnlock.link.enabled'] ? "Enabled" : "Disabled"} />
                                </FieldContainer>

                                { formData['contentUnlock.link.enabled'] &&
                                    <FieldContainer xs={12}>
                                        <InputLabel shrink>Service Links</InputLabel>
                                        <UriList
                                            name="contentUnlock.link.uris"
                                            value={formData['contentUnlock.link.uris'] || null}
                                            onChange={(newValue) => {
                                                setFormDataSingle('contentUnlock.link.uris', newValue);
                                            }}
                                        />
                                    </FieldContainer>
                                }

                            </Fieldset>
                        </Accordion>

                        <Accordion header="Video" subHeader={<>The user unlocks an uploaded video upon entering the campaign.</>}>
                            <Fieldset>
                                <FieldContainer xs={12}>
                                    <FormControlLabel control={<Switch name="contentUnlock.video.enabled" onChange={handleFormDataChange} checked={formData['contentUnlock.video.enabled'] ? true : false} />} label={formData['contentUnlock.video.enabled'] ? "Enabled" : "Disabled"} />
                                </FieldContainer>

                                { formData['contentUnlock.video.enabled'] &&
                                    <FieldContainer xs={12}>
                                        <InputLabel shrink>Video File <Tooltip title={<>Support formats: <strong>MP4</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>


                                            <Card sx={{backgroundColor: '#f4f4f4', p: 1}}>
                                                <CardActions>
                                                    <Typography variant="body1">{ formData['contentUnlock.video.uris'] || 'No file selected' }</Typography>
                                                </CardActions>
                                            </Card>

                                            <Card sx={{mt: 1}}>
                                                <CardActions>
                                                    <Button onClick={toggleUnlockVideoModal} startIcon={<CloudUploadIcon />}>
                                                    Upload
                                                    </Button>
                                                </CardActions>
                                            </Card>

                                    </FieldContainer>                                
                                }

                            </Fieldset>
                        </Accordion>

                        <Accordion header="Audio" subHeader={<>The user unlocks uploaded audio upon entering the campaign.</>}>
                            <Fieldset>
                                <FieldContainer xs={12}>
                                    <FormControlLabel control={<Switch name="contentUnlock.audio.enabled" onChange={handleFormDataChange} checked={formData['contentUnlock.audio.enabled'] ? true : false} />} label={formData['contentUnlock.audio.enabled'] ? "Enabled" : "Disabled"} />
                                </FieldContainer>

                                { formData['contentUnlock.audio.enabled'] &&
                                    <FieldContainer xs={12}>
                                        <InputLabel shrink>Audio File <Tooltip title={<>Support formats: <strong>MP3</strong>, <strong>OGG</strong> & <strong>WAV</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <FileUpload
                                            value={formData['contentUnlock.audio.uris'] || ''}
                                            onChange={(value) => {
                                                setFormDataSingle('contentUnlock.audio.uris', value);
                                            }}
                                        />
                                    </FieldContainer>
                                }

                            </Fieldset>
                        </Accordion>

                        <Accordion header="Image" subHeader={<>The user unlocks an uploaded image upon entering the campaign.</>}>
                            <Fieldset>
                                <FieldContainer xs={12}>
                                    <FormControlLabel control={<Switch name="contentUnlock.image.enabled" onChange={handleFormDataChange} checked={formData['contentUnlock.image.enabled'] ? true : false} />} label={formData['contentUnlock.image.enabled'] ? "Enabled" : "Disabled"} />
                                </FieldContainer>

                                { formData['contentUnlock.image.enabled'] &&
                                    <FieldContainer xs={12}>
                                        <InputLabel shrink>Image File <Tooltip title={<>Support formats: <strong>PNG</strong>, <strong>JPG</strong> & <strong>WEBP</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <FileUpload
                                            value={formData['contentUnlock.image.uris'] || ''}
                                            onChange={(value) => {
                                                setFormDataSingle('contentUnlock.image.uris', value);
                                            }}
                                        />
                                    </FieldContainer>
                                }

                            </Fieldset>
                        </Accordion>
                    </>
                }
            </>
        });
    //}

    tabData.push({
        label: 'Additional Data',
        content: <>

            <Accordion header="Additional Data" subHeader={<>The user will be asked to enter this additional data at the same time as they are prompted to opt in to mailing lists & agree to terms and conditions. Asking too many questions at this stage may discourage the user from completing their entry to the campaign.<br />It's also important to remember that we're only allowed to collect and store necessary data so it's worth only asking questions that are relevant to the campaign.<br />The field "Name" must match up with the fields in CDC/ExactTarget so please ensure that you don' change these values unless you know what you're doing to avoid loss of or issues with data.</>}>
                <Fieldset>
                    <FieldContainer xs={12}>
                        <FormControlLabel control={<Switch name="additionalDataForm.enabled" onChange={handleFormDataChange} checked={formData['additionalDataForm.enabled'] ? true : false} />} label={formData['additionalDataForm.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>

                    { formData['additionalDataForm.enabled'] &&
                        <>
                            <FieldContainer xs={6}>
                                <InputLabel shrink htmlFor="additionalDataForm.submitButton.text">Button Text <Tooltip title={<>Text that will appear on the form\'s "Submit" button.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    value={formData['additionalDataForm.submitButton.text'] || ''}
                                    name="additionalDataForm.submitButton.text"
                                    placeholder="Save & Continue"
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink htmlFor="additionalDataForm.content">Title <Tooltip title={<>Small piece of text that will appear above the form, prompting user to enter their details.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <RichTextEditor
                                    value={formData['additionalDataForm.content']}
                                    onEditorChange={(value) => {
                                        setFormDataSingle('additionalDataForm.content', value);
                                    }}
                                    name="additionalDataForm.content"
                                    size="medium"
                                />
                            </FieldContainer>


                            <FieldContainer xs={12}>
                                <FormBuilderFields name="additionalDataForm.fields" onChange={(newValue) => { setFormDataSingle('additionalDataForm.fields', newValue); }} value={formData['additionalDataForm.fields']} />
                            </FieldContainer>
                        </>
                    }

                </Fieldset>
            </Accordion>
            
            <Accordion header="Competition Entry" subHeader={<>Ask the user a questrion to add a competitive element to the campaign.</>}>
                <Fieldset>
                    <FieldContainer xs={6}>
                        <FormControlLabel control={<Switch name="entryQuestion.enabled" onChange={handleFormDataChange} checked={formData['entryQuestion.enabled'] ? true : false} />} label={formData['entryQuestion.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>

                    { formData['entryQuestion.enabled'] &&
                        <>
                            <FieldContainer xs={6}>
                                <InputLabel shrink htmlFor="entryQuestion.text">Entry Question Text <Tooltip title={<>This is the question the user will be asked & need to answer in order to enter the competition.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    value={formData['entryQuestion.text'] || ''}
                                    name="entryQuestion.text"
                                    placeholder="e.g. Why do you think you should win tickets to see Jason Derulo?"
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>
                        </>
                    }

                </Fieldset>
            </Accordion>

            <Accordion header="Competition Option" subHeader={<>Ask the user to choose from a list of options. Can be useful if the user needs to choose which venue they'd like to attend as part of a give-away.</>}>
                <Fieldset>
                    <FieldContainer xs={12}>
                        <FormControlLabel control={<Switch name="entryOption.enabled" onChange={handleFormDataChange} checked={formData['entryOption.enabled'] ? true : false} />} label={formData['entryOption.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>

                    { formData['entryOption.enabled'] &&
                        <>
                            <FieldContainer xs={6}>
                                <InputLabel shrink htmlFor="entryOption.text">Question <Tooltip title={<>This is the question that the user will be asked.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    value={formData['entryOption.text'] || ''}
                                    name="entryOption.text"
                                    placeholder="e.g. Why do you think you should win tickets to see Jason Derulo?"
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink htmlFor="entryOption.list">Option List <Tooltip title={<>This is the list of options that the user will be choosing from. Each option should be on a new line.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <TextField
                                    value={Array.isArray(formData['entryOption.list']) ? formData['entryOption.list'].join("\n") : ''}
                                    name="entryOption.list"
                                    multiline
                                    rows={8}
                                    fullWidth
                                    placeholder="e.g.
Manchester - 01/07
Liverpool - 13/07
Glasgow - 21/07
London - 03/08"
                                    onChange={(e) => {
                                        setFormDataSingle('entryOption.list', e.target.value.split("\n"));
                                    }}
                                />
                            </FieldContainer>


                        </>
                    }

                </Fieldset>
            </Accordion>
        </>




    });

    tabData.push({
        label: 'Visual',
        content: <>
            <Accordion header="Button Styles" subHeader={<>Styles for the various service buttons and external links.</>}>
                {
                    ( !productFamilyList || ( productFamilyList.length === 0 ) ) &&
                        <Alert severity="info">Please choose which services or links you'd like to include, using the <strong>Setup</strong> tab.</Alert>
                }
                {
                    ( productFamilyList && ( productFamilyList.length > 0 ) ) &&
                    <>
                        <Fieldset>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Button Type</InputLabel>

                                <RadioGroup row name="theme.layout.buttonType" value={formData['theme.layout.buttonType'] || ""} onChange={handleFormDataChange}>
                                    <FormControlLabel value={""} control={<Radio />} label="Standard" />
                                    <FormControlLabel value={"branded"} control={<Radio />} label="Branded" />
                                </RadioGroup>
                            </FieldContainer>
                            <FieldContainer xs={12}>
                                <Divider />
                            </FieldContainer>
                        </Fieldset>
                    {
                        productFamilyList.map( ( productFamily, productFamilyIndex ) => {
                            var serviceTheme = formData['serviceThemes'] ? formData['serviceThemes'].find(serviceThemes => serviceThemes.id === productFamily.id) : undefined;


                            let backgroundColor = serviceTheme && serviceTheme.backgroundColor ? serviceTheme.backgroundColor : productFamily.defaultBackgroundColor;
                            let textColor = serviceTheme && serviceTheme.textColor ? serviceTheme.textColor : productFamily.defaultTextColor;
                            let label = productFamily.label;

                            return (
                                <Fragment key={productFamily.id}>

                                    {
                                        formData['theme.layout.buttonType'] === 'branded' ?
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Fieldset>
                                                        <FieldContainer xs={3}></FieldContainer>
                                                        <FieldContainer xs={3}>
                                                            <Box sx={{height: '40px'}} src={productFamily.logo} component="img" />
                                                        </FieldContainer>
                                                        <FieldContainer xs={3} textAlign="right">
                                                            <Button
                                                                size="large"
                                                                sx={{
                                                                    borderRadius: formData['buttonTheme.borderRadius'],
                                                                    backgroundColor: formData['brandedButtonTheme.backgroundColor'],
                                                                    color: formData['brandedButtonTheme.textColor'],
                                                                    '&:hover' : {
                                                                        backgroundColor: formData['brandedButtonTheme.backgroundColor'],
                                                                        color: formData['brandedButtonTheme.textColor'],
                                                                    }
                                                                }}
                                                                disableRipple
                                                                variant="outlined"
                                                            >{label}</Button>
                                                        </FieldContainer>
                                                        <FieldContainer xs={3}></FieldContainer>
                                                    </Fieldset>
                                                </Grid>
                                            </Grid>
                                        :
                                            <Grid container>
                                                <Grid item xs={6}>

                                                    <Fieldset>
                                                        
                                                        <FieldContainer xs={6}>
                                                            <InputLabel shrink>Background Color <Tooltip title={<>The background colour of the call to action button.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                            <TextField
                                                                type="color"
                                                                value={backgroundColor}
                                                                fullWidth
                                                                onChange={e => {
                                                                    let currentThemes = formData['serviceThemes'] ? [...formData['serviceThemes']] : [];
                                                                    let currentTheme = currentThemes.find(currentTheme => currentTheme.id === productFamily.id);

                                                                    if( !currentTheme )
                                                                    {
                                                                        currentTheme = {id: productFamily.id};
                                                                        currentThemes.push(currentTheme);
                                                                    }

                                                                    currentTheme.backgroundColor = e.target.value;

                                                                    setFormDataSingle('serviceThemes', currentThemes);
                                                                }}
                                                            />
                                                        </FieldContainer>

                                                        <FieldContainer xs={6}>
                                                            <InputLabel shrink>Text Color <Tooltip title={<>The text colour of the call to action button.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                            <TextField
                                                                type="color"
                                                                value={textColor}
                                                                fullWidth
                                                                onChange={e => {
                                                                    let currentThemes = formData['serviceThemes'] ? [...formData['serviceThemes']] : [];
                                                                    let currentTheme = currentThemes.find(currentTheme => currentTheme.id === productFamily.id);

                                                                    if( !currentTheme )
                                                                    {
                                                                        currentTheme = {id: productFamily.id};
                                                                        currentThemes.push(currentTheme);
                                                                    }

                                                                    currentTheme.textColor = e.target.value;

                                                                    setFormDataSingle('serviceThemes', currentThemes);
                                                                }}
                                                            />
                                                        </FieldContainer>

                                                    </Fieldset>
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    xs={6}
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    direction="row"
                                                >
                                                    <Button
                                                        startIcon={productFamily.icon}
                                                        size="large"
                                                        sx={{
                                                            borderRadius: formData['buttonTheme.borderRadius'],
                                                            backgroundColor: backgroundColor,
                                                            color: textColor,
                                                            '&:hover' : {
                                                                backgroundColor: backgroundColor,
                                                                color: textColor,
                                                            }
                                                        }}
                                                        disableRipple
                                                        variant="contained"
                                                    >{label}</Button>
                                                </Grid>
                                            </Grid>
                                    }
                                    
                                    <Fieldset>
                                        {
                                            ( productFamilyIndex + 1 ) !== productFamilyList.length && 
                                                <FieldContainer xs={12}>
                                                    <Divider />
                                                </FieldContainer>
                                        }
                                    </Fieldset>
                        
                                </Fragment>
                            );
                        })
                    }

                    {
                        ( formData['theme.layout.buttonType'] === 'branded' ) &&
                        <Grid container>
                            <Fieldset>
                                <FieldContainer xs={12}>
                                    <Divider />
                                </FieldContainer>
                            </Fieldset>

                            <Grid item xs={3}></Grid>
                            <Grid item xs={6}>

                                <Fieldset>

                                    <FieldContainer xs={6}>
                                        <InputLabel shrink>Background Color <Tooltip title={<>The background colour of the call to action button.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <TextField
                                            type="color"
                                            value={formData['brandedButtonTheme.backgroundColor']}
                                            fullWidth
                                            onChange={e => {
                                                setFormDataSingle('brandedButtonTheme.backgroundColor', e.target.value);
                                            }}
                                        />
                                    </FieldContainer>

                                    <FieldContainer xs={6}>
                                        <InputLabel shrink>Text Color <Tooltip title={<>The text colour of the call to action button.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <TextField
                                            type="color"
                                            value={formData['brandedButtonTheme.textColor']}
                                            fullWidth
                                            onChange={e => {
                                                setFormDataSingle('brandedButtonTheme.textColor', e.target.value);
                                            }}
                                        />
                                    </FieldContainer>

                                </Fieldset>
                            </Grid>

                        </Grid>
                    }
                    
                        
                    </>
                }

            </Accordion>
            
            <Accordion header="Default Button Style" subHeader={<>Styles for default buttons on landing pages.</>}>
                <Grid container>
                    <Grid item xs={6}>

                        <Fieldset>
                            <FieldContainer xs={6}>
                                <InputLabel shrink>Background Color <Tooltip title={<>The background colour of the call to action button.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    type="color"
                                    value={formData['buttonTheme.backgroundColor']}
                                    fullWidth
                                    onChange={e => {
                                        setFormDataSingle('buttonTheme.backgroundColor', e.target.value);
                                    }}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Text Color <Tooltip title={<>The text colour of the call to action button.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    type="color"
                                    value={formData['buttonTheme.textColor']}
                                    fullWidth
                                    onChange={e => {
                                        setFormDataSingle('buttonTheme.textColor', e.target.value);
                                    }}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Border Radius <Tooltip title={<>The radius of button borders.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <Slider defaultValue={3} max={10} onChange={handleFormDataChange} valueLabelDisplay="auto" value={parseInt(formData['buttonTheme.borderRadius']) || null} name="buttonTheme.borderRadius" />
                            </FieldContainer>
                        
                        </Fieldset>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={6}
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                    >
                        <Button
                            size="large"
                            sx={{
                                backgroundColor: formData['buttonTheme.backgroundColor'],
                                color: formData['buttonTheme.textColor'],
                                borderRadius: formData['buttonTheme.borderRadius'],
                                '&:hover' : {
                                    backgroundColor: formData['buttonTheme.backgroundColor'],
                                    color: formData['buttonTheme.textColor'],
                                }
                            }}
                            disableRipple
                            variant="contained"
                        >Default Button</Button>
                    </Grid>

                </Grid>
            </Accordion>

            <Accordion header="Design" subHeader={<>Styles for various parts of the landing page.</>}>
                <Grid container>
                    <Grid item xs={12} lg={8}>

                        <Fieldset>
                            <FieldContainer xs={6}>
                                <InputLabel shrink>Borders <Tooltip title={<>Borders appear on several elements on campaign pages. You can disable these border by setting this field to <strong>"No"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <FormControlLabel control={<Switch name="theme.borders.enabled" onChange={handleFormDataChange} checked={formData['theme.borders.enabled'] ? true : false} />} label={formData['theme.borders.enabled'] ? "Enabled" : "Disabled"} />
                            </FieldContainer>

                            { formData['theme.borders.enabled'] &&
                                <FieldContainer xs={6}>
                                    <InputLabel shrink>Border Color <Tooltip title={<>Campaign pages feature borders on the image and around the description box. These borders are white by default but you can change them using this colour picker.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                    <TextField
                                        type="color"
                                        value={formData['theme.borders.color'] || '#ffffff'}
                                        name="theme.borders.color"
                                        fullWidth
                                        onChange={handleFormDataChange}
                                    />
                                </FieldContainer>
                            }

                            <FieldContainer xs={12}>
                                <Divider />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Title Text Color <Tooltip title={<>The color of the campaigns title text that sits below the campaign logo.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <TextField
                                    type="color"
                                    value={formData['theme.titleText.color'] || '#ffffff'}
                                    name="theme.titleText.color"
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Body Text Color <Tooltip title={<>Changes the body text color of the campaign. This includes everything excluding footer text, popups & the page title.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <TextField
                                    type="color"
                                    value={formData['theme.text.color'] || '#ffffff'}
                                    name="theme.text.color"
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Title Position <Tooltip title={<>Choose whether the title appears at the top of the page, above the image & description, or as part of the description to the right of the image.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <Select
                                    onChange={handleFormDataChange}
                                    value={formData['theme.layout.titlePosition'] || 'top'}
                                    name="theme.layout.titlePosition"
                                    fullWidth
                                >
                                    <MenuItem value="top">Top of Page</MenuItem>
                                    <MenuItem value="description">Description Box</MenuItem>
                                </Select>
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Font Size <Tooltip title={<>Changes the font size of the description text.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <Select
                                    onChange={handleFormDataChange}
                                    value={formData['theme.text.size'] || 'standard'}
                                    name="theme.text.size"
                                    fullWidth
                                >
                                    <MenuItem value="standard">Standard</MenuItem>
                                    <MenuItem value="large">Large</MenuItem>
                                    <MenuItem value="extra-large">Extra Large</MenuItem>
                                </Select>
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Font <Tooltip title={<>By default campaigns use a font called <strong>"Open Sans"</strong>, which is a crisp readable font. If you\'d like to use a different  font then you can choose one from this list. This font applies to all text on the campaign page.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <Select
                                    onChange={handleFormDataChange}
                                    value={formData['theme.font'] || null}
                                    name="theme.font"
                                    fullWidth
                                >
                                    {
                                        ( !fontLoading && fontData.getItems() ) &&
                                            fontData.getItems().map(font => {
                                                return (
                                                    <MenuItem key={font.getId()} value={font.getId()}>{font.getName()}</MenuItem>
                                                )
                                            })
                                        
                                    }
                                </Select>
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <Divider />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Content Background Color <Tooltip title={<>Changes the background color of the container within which the description sits.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <TextField
                                    type="color"
                                    value={formData['theme.container.background.color'] || '#000000'}
                                    name="theme.container.background.color"
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Content Background Opacity <Tooltip title={<>The semi-transparent black background upon which the description sits, can be made more opaque by sliding the bar to the right or more transparent by sliding the bar to the left.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <Slider defaultValue={50} max={100} onChange={handleFormDataChange} valueLabelDisplay="auto" value={parseInt(formData['theme.container.background.opacity']) || null} name="theme.container.background.opacity" />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Content Border Radius <Tooltip title={<>The radius of content area borders.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <Slider defaultValue={3} max={10} onChange={handleFormDataChange} valueLabelDisplay="auto" value={parseInt(formData['theme.container.borderRadius']) || null} name="theme.container.borderRadius" />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Content Vertical Padding <Tooltip title={<>This will add (right) or remove (left) padding from the top and bottom of the description text container. This can be useful if you would  like the image, to the left of the description, to appear larger on the page.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <Slider defaultValue={4} max={100} onChange={handleFormDataChange} valueLabelDisplay="auto" value={parseInt(formData['theme.container.verticalPadding']) || null} name="theme.container.verticalPadding" />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <Divider />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Background Color <Tooltip title={<>Changes the background color of the campaign. This only applies if there\'s no background image supplied.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <TextField
                                    type="color"
                                    value={formData['theme.background.color'] || '#000000'}
                                    name="theme.background.color"
                                    fullWidth
                                    onChange={handleFormDataChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Background Position <Tooltip title={<>By default the background will be positioned in the center of the screen. Changing this value to "Top" will mean the top of the background image is flush with the top of the screen. This will help when certain parts of an image are cut off when centered.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <Select
                                    onChange={handleFormDataChange}
                                    value={formData['theme.background.image.position'] || 'center'}
                                    name="theme.background.image.position"
                                    fullWidth
                                >
                                    <MenuItem value="center">Center</MenuItem>
                                    <MenuItem value="top">Top</MenuItem>
                                </Select>
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Background Blur <Tooltip title={<>If you would like to add an element of blurring to the image, then you can enter a number between 0 (No Blur) and 100 (Maximum Blur).</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <Slider defaultValue={0} max={100} onChange={handleFormDataChange} valueLabelDisplay="auto" value={parseInt(formData['theme.background.image.blur']) || null} name="theme.background.image.blur" />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <Divider />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Website Icon <Tooltip title={<>The shortcut icon is the small icon that appears next to the page title in the browser tag. By default this is the WMG logo but you can change it by uploading an alternative image here. Should be no bigger than <strong>100x100</strong> with a file-size no greater than <strong>500Kb</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <ImageUpload
                                    size="small"
                                    value={formData['theme.icon.url'] || ''}
                                    onChange={(value) => {
                                        setFormDataSingle('theme.icon.url', value);
                                    }}
                                />
                            </FieldContainer>

                        </Fieldset>
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                </Grid>
            </Accordion>

            <Accordion header="Design: Mobile">
                <Fieldset>
                <FieldContainer xs={12}>
                        <InputLabel shrink>Show Image on Mobile <Tooltip title={<>Show the campaign's main image on mobile? By default the campaign\'s main image is hidden to improve the mobile experience & draw the user's attention to the call to action.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                        <FormControlLabel control={<Switch name="theme.mobile.image.enabled" onChange={handleFormDataChange} checked={formData['theme.mobile.image.enabled'] ? true : false} />} label={formData['theme.mobile.image.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>
                    
                    <FieldContainer xs={6}>
                        <InputLabel shrink>Mobile Background Color <Tooltip title={<>If you'd like to use a separate colour for the mobile view of the site (For text visibility or design purposes) then you can choose an alternate colour here. Make sure you check the box to the right to <strong>"Yes"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                        <FormControlLabel control={<Switch name="theme.mobile.background.color.enabled" onChange={handleFormDataChange} checked={formData['theme.mobile.background.color.enabled'] ? true : false} />} label={formData['theme.mobile.background.color.enabled'] ? "Enabled" : "Disabled"} />
                    </FieldContainer>

                    { formData['theme.mobile.background.color.enabled'] &&
                        <FieldContainer xs={6}>
                            <InputLabel shrink>Background Color</InputLabel>

                            <TextField
                                type="color"
                                value={formData['theme.mobile.background.color.value'] || '#000000'}
                                name="theme.mobile.background.color.value"
                                fullWidth
                                onChange={handleFormDataChange}
                            />
                        </FieldContainer>
                    }

                </Fieldset>
            </Accordion>
        </>
    });

    return (
        <>
        {
            campaignData &&
            <>
                <ModalDialogue open={downloadFilesModal} onClose={toggleDownloadFilesModal}>
                    <Box sx={{px: 2, pt: 2, pb: 1}}>
                        <Typography variant="h4">Upload File</Typography>

                        <LargeFileUpload
                            path="redeemable-code/files"
                            onUpload={file => {
                                setDownloadFilesModal(false);

                                acquisitionRepository.createDownloadFile(campaignFormData['download'], {
                                    location: file
                                });

                                setDownloadFileLoading(true);

                                // TODO: This should not repeat code
                                acquisitionRepository.getDownloadFiles(campaignFormData['download'])
                                    .then(downloadFiles => {
                                        setDownloadFileData(downloadFiles);
                                        setDownloadFileLoading(false);
                                    });
                            }}
                        />
                    </Box>
                </ModalDialogue>
                
                <ModalDialogue open={optInExamplesModal} onClose={toggleOptInExamplesModal}>
                    <Box sx={{px: 2, pt: 2, pb: 1}}>
                        <Typography variant="h4" sx={{mb: 2}}>Opt In Examples</Typography>

                        <Typography>
<strong>One Global Consent:</strong><br />
Yes, I want to receive marketing messages with the latest news, events and releases from Warner Music Group companies. I understand that these messages are based on my information, interests, website visits, activities and device data.<br />
<br />
<strong>Only Artist:</strong><br />
Yes, I want to receive marketing messages with the latest news, events and releases from Artist name and Warner Music Group companies. I understand that these messages are based on my information, interests, website visits, activities and device data.<br />
<br />
<strong>Global Consent and Artists:</strong><br />
Yes, I want to receive marketing messages with the latest news, events and releases from Artist name and Warner Music Group companies. I understand that these messages are based on my information, interests, website visits, activities and device data.<br />
<br />
<strong>Global Consent, Label name and Artists:</strong><br />
Yes, I want to receive marketing messages with the latest news, events and releases from Artist name, Label name and Warner Music Group companies. I understand that these messages are based on my information, interests, website visits, activities and device data.
                        </Typography>
                    </Box>
                </ModalDialogue>
                    
                <ModalDialogue open={unlockVideoModal} onClose={toggleUnlockVideoModal}>
                    <Box sx={{px: 2, pt: 2, pb: 1}}>
                        <Typography variant="h4">Upload File</Typography>

                        <LargeFileUpload
                            path="uploads"
                            onUpload={file => {
                                setFormDataSingle('contentUnlock.video.uris', file);
                                setUnlockVideoModal(false);
                            }}
                        />
                    </Box>
                </ModalDialogue>

                <ModalDialogue open={campaignLocaleModal} onClose={toggleCampaignLocaleModal}>
                    <Box sx={{px: 2, pt: 2, pb: 1}}>

                        <Typography variant="h4">Campaign Territories</Typography>

                        <Button sx={{mt: 2}} onClick={toggleCampaignLocaleCreateModal} endIcon={<AddBoxIcon />} variant="contained">Add New Territory</Button>

                                {
                                    campaignData.getLocales().map(campaignLocale => {
                                        let countryNames = [];
                                        let showAllCountries = false;

                                        campaignLocale.getCountries().forEach(country => {
                                            countryNames.push(<Fragment key={country.getId()}><Chip sx={{margin: '3px'}} label={<><Flag height="11" width="17" code={country.getA2()} /> {country.getName()}</>} /></Fragment>);
                                        });

                                        let rowTitle = '';

                                        if( countryNames.length === 0 )
                                        {
                                            if( campaignLocale.getIsGlobal() === true )
                                            {
                                                rowTitle = <Chip sx={{margin: '3px'}} label="Global" />;
                                            }
                                            else
                                            {
                                                rowTitle = 'Undefined';
                                            }
                                        }
                                        else
                                        {
                                            var countryText = countryNames;

                                            if( campaignLocale.getIsGlobal() === true )
                                            {
                                                rowTitle = countryText;
                                            }
                                            else
                                            {
                                                rowTitle = countryText;
                                            }
                                        }

                                        return (
                                            <TableContainer key={campaignLocale.getId()} sx={{mt: 2, mb: 2}} component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {
                                                                ( campaignLocale.getIsGlobal() && countryNames.length === 0 ) ?
                                                                    <TableCell sx={{width: '60%', fontWeight: 'bold'}}>{rowTitle}</TableCell>
                                                                :
                                                                    ( countryNames.length === 0 ) ?
                                                                        <TableCell sx={{width: '60%', fontStyle: 'italic'}}>{rowTitle}</TableCell>
                                                                    :
                                                                        <TableCell sx={{width: '60%'}}>{showAllCountries ? rowTitle : rowTitle.slice(0, 5)}{ ( !showAllCountries && countryNames.length > 5 ) && <Button variant="text" size="small">and {countryNames.length - 5} more</Button> }</TableCell>
                                                            }
                                                            <TableCell sx={{width: '40%', textAlign: 'right', pl: 0}}>
                                                                <Button component={Link} to={`/campaigns/${campaignData.getId()}/edit/${campaignLocale.getId()}`} onClick={toggleCampaignLocaleModal} variant="outlined">Edit</Button>
                                                                <Button sx={{ml: 1}} endIcon={<OpenInNewIcon />} target="_blank" component={MLink} href={`${process.env.REACT_APP_FRONTEND_URL}${campaignData.getFullUrlSlug()}?country=${campaignLocale.getCountries() && ( campaignLocale.getCountries().length > 0 ) ? campaignLocale.getCountries()[0].getA2() : 'WW'}`} variant="outlined">Preview</Button>
                                                                {
                                                                    !campaignLocale.getIsGlobal() &&
                                                                        <Button onClick={toggleCampaignLocaleModal} variant="outlined" color="error" sx={{ml: 1}}>Delete</Button>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </TableContainer>
                                        );
                                    })
                                }
                                                
                    </Box>
                </ModalDialogue>

                <ModalDialogue showFooter={false} open={campaignTranslationDeleteModal} onClose={toggleCampaignTranslationDeleteModal}>
                    <Box sx={{px: 2, pt: 2, pb: 1}}>

                        <Typography mb={2} variant="h4">Delete Translation</Typography>
                        <Typography variant="body1">Are you sure you want to delete this translation? This action cannot be undone.</Typography>

                        <Box sx={{textAlign: 'right'}}>
                            <Button
                                onClick={() => {
                                    setCampaignTranslationDeleteId(null);
                                    toggleCampaignTranslationDeleteModal();
                                }}
                                sx={{mt: 2, ml: 2}}
                            >
                                Cancel
                            </Button>

                            <Button
                                onClick={() => {
                                    acquisitionAPI.deleteCampaignTranslation(campaignData.getId(), campaignLocale.getId(), campaignTranslationDeleteId).then(response => {
                                        enqueueSnackbar('Translation deleted', {
                                            variant: 'success'
                                        });
                                        
                                        history.push('/campaigns/'+campaign.getId()+'/edit/'+campaignLocale.getId());

                                        toggleCampaignTranslationDeleteModal();
                                    });
                                }}
                                sx={{mt: 2, ml: 2}
                            }>
                                Delete
                            </Button>
                        </Box>

                    </Box>
                </ModalDialogue>
                
                <ModalDialogue open={campaignTranslationModal} onClose={toggleCampaignTranslationModal}>
                    <Box sx={{px: 2, pt: 2, pb: 1}}>

                        <Typography gutterBottom variant="h4">Translations For This Campaign</Typography>
                        <Typography gutterBottom>Use the options below to create a new translation for this campaign or edit an existing translation.</Typography>

                        <Button sx={{cmt: 2}} onClick={toggleCampaignTranslationCreateModal} endIcon={<AddBoxIcon />} variant="contained">Create Translation</Button>

                        <TableContainer sx={{mt: 2, mb: 2}} component={Paper}>
                            <Table>
                                <TableBody>
                                {
                                    campaignLocale.getTranslations().map(campaignTranslation => {

                                        return (
                                            <TableRow
                                                key={campaignTranslation.getId()}
                                            >
                                                {
                                                    campaignTranslation.getIsDefault() ?
                                                        <TableCell sx={{fontWeight: 'bold'}}>{campaignTranslation.getLanguage().getName()} (Primary)</TableCell>
                                                    :
                                                        <TableCell>{campaignTranslation.getLanguage().getName()}</TableCell>
                                                }
                                                <TableCell sx={{textAlign: 'right'}}>
                                                    <Button component={Link} to={`/campaigns/${campaignData.getId()}/edit/${campaignLocale.getId()}/${campaignTranslation.getId()}`} onClick={toggleCampaignTranslationModal} variant="outlined">Edit</Button>
                                                    <Button sx={{ml: 1}} endIcon={<OpenInNewIcon />} target="_blank" component={MLink} href={`${process.env.REACT_APP_FRONTEND_URL}${campaignData.getFullUrlSlug()}?country=${campaignLocale.getCountries() && ( campaignLocale.getCountries().length > 0 ) ? campaignLocale.getCountries()[0].getA2() : 'WW'}&language=${campaignTranslation.getLanguage().getA2()}`} variant="outlined">Preview</Button>
                                                    {
                                                        !campaignTranslation.getIsDefault() &&
                                                            <Button
                                                                onClick={() => {
                                                                    setCampaignTranslationDeleteId(campaignTranslation.getId());

                                                                    toggleCampaignTranslationDeleteModal();
                                                                }}
                                                                variant="outlined"
                                                                color="error"
                                                                sx={{ml: 1}}
                                                            >
                                                                Delete
                                                            </Button>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        
                    </Box>
                </ModalDialogue>
                
                <ModalDialogue open={campaignTranslationCreateModal} onClose={toggleCampaignTranslationCreateModal}>
                    <Box sx={{px: 2, pt: 2, pb: 1}}>

                        <Typography variant="h4">New Campaign Translation</Typography>
                        

                        <Box
                            sx={{mt: 2}}
                        >
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();

                                    setTranslationSubmitting(true);

                                    acquisitionAPI.createCampaignTranslation( campaign.getId(), campaignLocale.getId(), {language: translationCreateFormData.translationLanguage, isDefault: false} )
                                        .then(response => {
                                            setTranslationSubmitting(false);
                                            setCampaignTranslationCreateModal(false);

                                            enqueueSnackbar('New translation created', {
                                                variant: 'success'
                                            });

                                            history.push('/campaigns/'+campaign.getId()+'/edit/'+campaignLocale.getId()+'/'+response.id);
                                        });
                                    
                                }}
                            >

                                <FieldContainer xs={6}>

                                    <InputLabel shrink>Language</InputLabel>

                                    {
                                        languageData && 

                                            <Select
                                                id="translationLanguage"
                                                name="translationLanguage"
                                                fullWidth
                                                onChange={handleTranslationCreateChange}
                                                value={translationCreateFormData['translationLanguage'] || ''}
                                            >
                                                <MenuItem value=""></MenuItem>
                
                                                {languageData.getItems().map(language => (
                                                    <MenuItem disabled={campaignLocale.getTranslations().find(translation => translation.getLanguage().getId() === language.getId()) ? true : false} key={language.getId()} value={language.getId()}>{language.getName()}</MenuItem>
                                                ))}
                                            </Select>
                                    }

                                </FieldContainer>

                                <LoadingButton
                                    disabled={translationCreateFormData['translationLanguage'] ? false : true}
                                    sx={{mt: 2, mb: 2}}
                                    variant="contained"
                                    type="submit"
                                    loading={translationSubmitting}
                                    loadingPosition="end"
                                    endIcon={<AddBoxIcon />}
                                >
                                    Create Translation
                                </LoadingButton>
                            </form>
                        </Box>

                        
                    </Box>
                </ModalDialogue>

                <ModalDialogue open={campaignLocaleCreateModal} onClose={toggleCampaignLocaleCreateModal}>
                    <Box sx={{px: 2, pt: 2, pb: 1}}>

                        <Typography variant="h4">New Campaign Territory</Typography>
                        

                        <Box
                            sx={{mt: 2}}
                        >
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();

                                    setLocaleSubmitting(true);

                                    acquisitionAPI.createCampaignLocale( campaign.getId(), {
                                        isGlobal: false,
                                        dateStart: campaignLocale.getDateStart(),
                                        dateFinish: campaignLocale.getDateFinish(),
                                        artist: campaignLocale.getArtist(),
                                        implementations: campaignLocale.getImplementations(),
                                        integrations: campaignLocale.getIntegrations()
                                    } )
                                        .then(newCampaignLocale => {
                                            let newCountryCodes = Array.from(localeCreateFormData['countries'], countrySingle => countrySingle.getA2());

                                            acquisitionAPI.addCampaignLocaleCountry(campaign.getId(), newCampaignLocale.id, newCountryCodes);
                                            
                                            acquisitionAPI.createCampaignTranslation( campaign.getId(), newCampaignLocale.id, {language: localeCreateFormData['translationLanguage'], isDefault: true} )
                                                .then(newCampaignTranslation => {
                                                    setLocaleSubmitting(false);
                                                    setCampaignLocaleCreateModal(false);

                                                    enqueueSnackbar('New '+( newCountryCodes.length > 1 ? 'territories' : 'territory' )+' added', {
                                                        variant: 'success'
                                                    });
        
                                                    history.push('/campaigns/'+campaign.getId()+'/edit/'+newCampaignLocale.id+'/'+newCampaignTranslation.id);
                                                });
                                        });
                                    
                                }}
                            >
                                <Fieldset>
                                    <FieldContainer xs={12}>
                                        <InputLabel shrink>Country</InputLabel>
                                        {
                                            countryData &&
                                                <CountrySelector
                                                    disabledOptions={Array.from(campaign.getLocales(), (item) => item.getCountries() ).flat()}
                                                    name="countries"
                                                    value={localeCreateFormData['countries'] || []}
                                                    options={countryData}
                                                    onChange={(event, newValue) => {
                                                        setLocaleCreateFormDataSingle('countries', newValue);
                                                    }}
                                                />
                                        }

                                    </FieldContainer>

                                    <FieldContainer xs={12}>

                                        <InputLabel shrink>Main Language</InputLabel>

                                        {
                                            languageData && 

                                                <Select
                                                    id="translationLanguage"
                                                    name="translationLanguage"
                                                    fullWidth
                                                    onChange={handleLocaleCreateChange}
                                                    value={localeCreateFormData['translationLanguage'] || ''}
                                                >
                                                    <MenuItem value=""></MenuItem>

                                                    {languageData.getItems().map(language => (
                                                        <MenuItem key={language.getId()} value={language.getId()}>{language.getName()}</MenuItem>
                                                    ))}
                                                </Select>
                                        }

                                    </FieldContainer>
                                    
                                    <FieldContainer xs={6}>
                                        <LoadingButton
                                            disabled={localeCreateFormData['translationLanguage'] && localeCreateFormData['countries'] ? false : true}
                                            sx={{mb: 2}}
                                            variant="contained"
                                            type="submit"
                                            loading={localeSubmitting}
                                            loadingPosition="end"
                                            endIcon={<AddBoxIcon />}
                                        >
                                            Add Territory
                                        </LoadingButton>
                                    </FieldContainer>
                                </Fieldset>

                            </form>
                        </Box>

                        
                    </Box>
                </ModalDialogue>

                <Hero>
                    <Box
                        sx={{
                            marginTop: campaignTypeSelectorExpanded ? 9 : 0,
                            transition: 'height 1s, margin 1s',
                            height: campaignTypeSelectorExpanded ? '650px' : '0px',
                            position: 'relative',
                            cursor: 'pointer'
                        }}
                    >
                        <CampaignTypeSelector
                            onClick={(e) => {
                                setCampaignTypeSelectorTransitioning(true);
                                setCampaignTypeSelectorExpanded(!campaignTypeSelectorExpanded);
                                setTimeout(function(){
                                    setCampaignTypeSelectorTransitioning(false);
                                }, 1000);
                            }}
                            expanded={campaignTypeSelectorExpanded}
                            transitioning={campaignTypeSelectorTransitioning}
                            sx={{
                                transition: 'width 1s, height 1s, left linear .5s, top linear .5s',
                                width: campaignTypeSelectorExpanded ? null : '200px',
                                left: campaignTypeSelectorExpanded ? '0px' : '-240px',
                                top: campaignTypeSelectorExpanded ? '0px' : '75px',
                                position: campaignTypeSelectorExpanded ? 'absolute' : 'absolute'
                            }}
                            onChange={(newValue) => { setLocaleFormDataSingle('types', [newValue]); }}
                            value={localeFormData['types'] ? localeFormData['types'][0] : null}
                            items={[
                                {
                                    title: 'Follow',
                                    backgroundColor: '#ff5421',
                                    icon: <CampaignFollowIcon />,
                                    value: 'follow',
                                    description: "Fans follow a selection of playlists, artists, albums and tracks."
                                },
                                {
                                    title: 'Save',
                                    backgroundColor: '#f5b996',
                                    icon: <CampaignSaveIcon />,
                                    value: 'save',
                                    description: "Fans enter the campaign and have an album or single added to their library."
                                },
                                {
                                    title: 'Pre-Save',
                                    backgroundColor: '#ff8bf5',
                                    icon: <CampaignPreSaveIcon />,
                                    value: 'presave',
                                    description: "Fans enter the campaign and have an album or single added to their library on the day of release."
                                },
                                {
                                    title: 'Content Unlock',
                                    backgroundColor: '#d2d2ff',
                                    icon: <CampaignContentUnlockIcon />,
                                    value: 'contentunlock',
                                    description: "Fans perform an action to unlock a piece of content which can be a video, image, audio file or message."
                                },
                                {
                                    title: 'Stream to Enter',
                                    backgroundColor: '#81c7b9',
                                    icon: <CampaignStreamToEnterIcon />,
                                    value: 'streamtoenter',
                                    description: "Fans must stream a playlist, album or track to enter this campaign."
                                },
                                {
                                    title: 'Listening History',
                                    backgroundColor: '#b4ff47',
                                    icon: <CampaignListeningHistoryIcon />,
                                    value: 'profile',
                                    description: "Uses a fan's listening history to look back at their favorite music and provide recommendations."
                                },
                                {
                                    title: 'Form',
                                    backgroundColor: '#6dea81',
                                    icon: <CampaignFormIcon />,
                                    value: 'form',
                                    description: "Fans enter the campaign by maually entering their personal details."
                                },
                                {
                                    title: 'Redeemable Download Code',
                                    backgroundColor: '#ff8153',
                                    icon: <CampaignRedeemableDownloadCodeIcon />,
                                    value: 'redeemabledownloadcode',
                                    description: "Fans enter a code to unlock a piece of content."
                                }
                            ]}
                        />
                    </Box>

                    <HeroHeader title={campaignData.getFullName()} subTitle="You're Editing">
                        <Box sx={{mb: 0.5}}>
                            <Button variant="outlined" onClick={toggleCampaignLocaleModal} endIcon={<EditIcon />}>For:&nbsp;<strong>
                                {
                                    campaignLocale.getIsGlobal() && ( campaignLocale.getCountries().length === 0 ) ?
                                        'Global'
                                    :
                                        campaignLocale.getCountries().length === 0 ?
                                            'Undefined'
                                        :
                                            campaignLocale.getCountries()
                                                .slice(0, 2)
                                                .map(country => {
                                                    return country.getName();
                                                })
                                                .join(', ')+
                                            ( campaignLocale.getCountries().length > 2 ? ' & '+( campaignLocale.getCountries().length - 2 )+' more' : '' )
                                }
                                </strong>
                            </Button>
                            
                            <Button sx={{ml: 1}} variant="outlined" onClick={toggleCampaignTranslationModal} endIcon={<EditIcon />}>In:&nbsp;<strong>
                                {
                                    campaignTranslation.getLanguage().getName()
                                }
                                </strong>
                            </Button>
                        </Box>
                    </HeroHeader>

                    <CampaignHeaderButtons options={['toolkit', 'view', 'stats']} campaign={campaignData} />
                </Hero>
                <PageContainer>
                    <form autoComplete="off">
                        <TabGroup sx={{mt: 4}} tabList={tabData} />
                    </form>
                    <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                        <Container maxWidth="xl">

                            <Toolbar spacing={3}>
                                <Typography variant="body1">

                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />

                                <LoadingButton
                                    loading={submitting}
                                    disabled={!pendingChanges}
                                    onClick={handleSubmit}
                                    loadingPosition="end"
                                    sx={{ml: 1}}
                                    variant="contained"
                                    size="large"
                                    endIcon={<SaveIcon />}
                                >
                                    Save Changes
                                </LoadingButton>
                            </Toolbar>

                        </Container>
                    </AppBar>
                </PageContainer>
            </>
        }
        </>
    );
};
